import { Box, Heading } from '@chakra-ui/react';
import React from 'react';

import { BackButton } from '@/components/BackButton';
import { SettingsAccessButton } from '@/components/PairingTerminal/SettingsAccessButton';

type HeaderProps = {
  title: string;
  hasArrowBack?: boolean;
  hasSettingsButton?: boolean;
  url?: string;
  children: React.ReactNode;
};

const titleDescriptionStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
};

const Header = ({ title, hasArrowBack = false, url, children, hasSettingsButton = false }: HeaderProps) => {
  return (
    <Box position="sticky" top="0" zIndex={1}>
      <Box backgroundColor="#FFFFFF" px={4} py={6} boxShadow="0px 4px 8px rgba(0, 0, 0, 0.03)" position="relative">
        {hasArrowBack && (
          <BackButton url={url} sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} />
        )}
        <Heading
          as="h4"
          size="md"
          fontWeight="500"
          textAlign="center"
          marginLeft="auto"
          maxW={hasArrowBack ? '90%' : '100%'}
          sx={titleDescriptionStyle}
        >
          {title}
        </Heading>
        {hasSettingsButton && (
          <SettingsAccessButton sx={{ position: 'absolute', right: 4, top: '50%', transform: 'translateY(-50%)' }} />
        )}
      </Box>
      {children}
    </Box>
  );
};

export default React.memo(Header);
