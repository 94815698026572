import { Box, Flex, Image, useCheckbox, useCheckboxGroup, VisuallyHidden } from '@chakra-ui/react';
import { sortBy } from 'lodash-es';

import { Concept } from '@/api/types';

type ConceptCheckboxProps = {
  concept: Concept;
};

// Recreate outline with border-radius manually to handle safari issue
// https://bugs.webkit.org/show_bug.cgi?id=20807
const baseStyle = {
  '&::before': {
    borderColor: 'gray.200',
    borderRadius: '13px',
    borderStyle: 'solid',
    borderWidth: '1px',
    bottom: '-2px',
    content: '""',
    left: '-2px',
    position: 'absolute',
    right: '-2px',
    top: '-2px',
  },
};

const checkedStyle = {
  '&::before': {
    borderColor: 'gray.600',
    borderRadius: '13px',
    borderStyle: 'solid',
    borderWidth: '2px',
    bottom: '-3px',
    content: '""',
    left: '-3px',
    position: 'absolute',
    right: '-3px',
    top: '-3px',
  },
};

const ConceptCheckbox = ({ concept, ...props }: ConceptCheckboxProps) => {
  const { getCheckboxProps, getInputProps, getLabelProps, htmlProps, state } = useCheckbox(props);
  const style = state.isChecked ? checkedStyle : baseStyle;

  return (
    <label {...htmlProps}>
      <input {...getInputProps()} hidden />
      <VisuallyHidden {...getLabelProps}>{concept.label}</VisuallyHidden>
      <Box
        cursor="pointer"
        height={'56px'}
        justifyContent="center"
        pos="relative"
        sx={style}
        width={'56px'}
        {...getCheckboxProps()}
      >
        <Image objectFit="contain" alt={concept.label} src={concept.logo} borderRadius="12px" boxSize="54px" />
      </Box>
    </label>
  );
};

type ConceptCheckboxGroupProps = {
  concepts: Concept[];
  defaultValue?: Array<string | number>;
  isDisabled?: boolean;
  onChange?: (value: string[]) => void;
  value?: Array<string | number>;
};

export const ConceptCheckboxGroup = ({ concepts, ...props }: ConceptCheckboxGroupProps) => {
  const { getCheckboxProps, onChange, isDisabled } = useCheckboxGroup({ ...props });

  return (
    <Flex align="center" gap={2} overflowY="auto" p={1}>
      {sortBy(concepts, 'label').map((concept) => (
        <ConceptCheckbox
          key={concept.uuid}
          concept={concept}
          {...getCheckboxProps({ value: concept.uuid, isDisabled, onChange })}
        />
      ))}
    </Flex>
  );
};
