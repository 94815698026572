import { ArrowForwardIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Container, Image, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';

import type { Concept, Coords, KitchenInfos } from '@/api/types';
import { useKitchensQuery } from '@/api/useKitchensQuery';
import simone from '@/assets/img/simone.png';
import TasterStretchGif from '@/assets/img/taster-stretch.gif';
import { ConceptCheckboxGroup } from '@/components/ConceptCheckboxGroup';
import { Geolocation, GeolocationAutocomplete } from '@/components/GeolocationAutocomplete';
import { KitchenGeolocationCard } from '@/components/KitchenGeolocationCard/KitchenGeolocationCard';
import { RequestLocationButton } from '@/components/RequestLocationButton';
import { UserLocationButton } from '@/components/UserLocationButton';
import { logLocationClick, logOrderDelivery } from '@/helpers/dataLayer.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { useSessionStorage } from '@/hooks/useSessionStorage';

type HeaderProps = {
  defaultAddress?: string;
  concepts?: Concept[];
  onChange: (value: string[]) => void;
  value: string[];
  onSelect: (value: Geolocation) => void;
};

const Header = ({ defaultAddress, concepts, onChange, value, onSelect }: HeaderProps) => {
  return (
    <VStack align="stretch" spacing={4} bgColor="white" paddingX={3} paddingBlock={4} borderRadius={8}>
      <GeolocationAutocomplete defaultValue={defaultAddress} onSelect={onSelect} />
      {concepts?.length && <ConceptCheckboxGroup concepts={concepts} onChange={onChange} value={value} />}
    </VStack>
  );
};

type BodyProps = {
  coords: Coords;
  isError?: boolean;
  isLoading?: boolean;
  kitchenInfos?: KitchenInfos[];
  selectedConceptsUuids?: string[];
};

const Body = ({ coords, isError, isLoading, kitchenInfos, selectedConceptsUuids }: BodyProps) => {
  if (kitchenInfos?.length && selectedConceptsUuids?.length) {
    const [closestKitchen, ...otherKitchens] = kitchenInfos;

    return (
      <VStack spacing={6} align="stretch" paddingX={3} paddingBlock={4}>
        <VStack align="stretch" spacing={6}>
          <KitchenGeolocationCard
            coords={coords}
            kitchenInfos={closestKitchen}
            selectedConceptUuids={selectedConceptsUuids}
          />
          <VStack align="stretch" spacing={4}>
            <Text alignSelf="center" display="flex" fontSize="sm" fontWeight={500} pos="relative" textAlign="center">
              <Trans>Don’t want to get off the couch?</Trans>
            </Text>
            <Button
              alignSelf="center"
              as={Link}
              colorScheme="gray"
              rightIcon={<ArrowForwardIcon />}
              to={`/${closestKitchen.locationUuid}?delivery=true`}
              onClick={() => logOrderDelivery({ kitchenLabel: closestKitchen.label })}
            >
              <Trans>Order delivery</Trans>
            </Button>
          </VStack>
        </VStack>
        {!!otherKitchens?.length && (
          <VStack align="stretch" spacing={4}>
            <Text
              fontSize="sm"
              textAlign="center"
              fontWeight={500}
              display="flex"
              sx={{
                '&::after': {
                  backgroundColor: 'gray.200',
                  content: '""',
                  flex: '1 1',
                  height: '1px',
                  marginBlock: 'auto',
                  marginX: 2,
                },
                '&::before': {
                  backgroundColor: 'gray.200',
                  content: '""',
                  flex: '1 1',
                  height: '1px',
                  marginBlock: 'auto',
                  marginX: 2,
                },
              }}
            >
              <Trans>Other locations near you</Trans>
            </Text>
            {otherKitchens.map((kitchenInfo, index) => (
              <KitchenGeolocationCard
                coords={coords}
                key={kitchenInfo.locationUuid + index}
                kitchenInfos={kitchenInfo}
                selectedConceptUuids={selectedConceptsUuids}
              />
            ))}
          </VStack>
        )}
      </VStack>
    );
  }

  if (isError) {
    return (
      <VStack height="100%" justify="center" align="center" spacing={6} paddingX={3}>
        <Image height={200} alt="Simone" src={simone} />
        <Text fontSize="sm" fontWeight={300} textAlign="center">
          <Trans>Sorry, we’ve encountered an error. Please try again later or contact support. </Trans>
        </Text>
        <Button id="support" alignSelf="center" colorScheme="gray">
          <Trans>Contact support</Trans>
        </Button>
      </VStack>
    );
  }

  if (isLoading) {
    return (
      <VStack height="100%" justify="center" align="center">
        <Image src={TasterStretchGif} />
      </VStack>
    );
  }

  return (
    <VStack height="100%" justify="center" align="center" spacing={4} paddingX={3}>
      <Text fontSize="sm" fontWeight={300} textAlign="center">
        <Trans>Snap! We don’t have Taster brands here yet.</Trans>
      </Text>
      <Text fontSize="sm" fontWeight={300} textAlign="center">
        <Trans>
          Why not submit your location and we will look into getting one of our brands in your location soon!
        </Trans>
      </Text>
      <RequestLocationButton alignSelf="center" colorScheme="gray" rightIcon={<ExternalLinkIcon />} />
    </VStack>
  );
};

export const LocationFinder = () => {
  usePageViewTracker();
  const [userLocation, setUserLocation] = useSessionStorage<Geolocation | null>('location', null);
  const { data, isError, isLoading } = useKitchensQuery({
    options: {
      enabled: !!userLocation,
      select: (response) => {
        return { ...response, kitchens: sortBy(response.kitchens, 'distance') };
      },
    },
    requestParams: userLocation?.coords as Coords,
  });

  const ConceptFilterParams = withDefault(ArrayParam, data?.concepts.map(({ uuid }) => uuid) ?? []);

  const [conceptsQuery, setConceptsQuery] = useQueryParams({
    conceptUuids: ConceptFilterParams,
  });

  const handleConceptsChange = (newConceptsUuids: string[]) => setConceptsQuery({ conceptUuids: newConceptsUuids });

  const selectedConceptsUuids = useMemo(() => {
    if (conceptsQuery.conceptUuids) return conceptsQuery.conceptUuids as string[];

    if (data?.concepts.length) return data?.concepts.map(({ uuid }) => uuid);

    return [];
  }, [conceptsQuery, data]);

  const filteredKitchens = useMemo(() => {
    if (!data?.kitchens) return;

    return data.kitchens.filter(({ menuInfos }) => {
      return menuInfos
        .map(({ conceptUuid }) => conceptUuid)
        .some((conceptUuid) => selectedConceptsUuids.includes(conceptUuid));
    });
  }, [data, selectedConceptsUuids]);

  const handleGeolocation = (value: Geolocation) => setUserLocation(value);

  const handleUserPosition = ({ coords }: GeolocationPosition) => {
    const { latitude, longitude } = coords;
    setUserLocation({ coords: { latitude, longitude } });
    logLocationClick();
  };

  return (
    <Container px={0} height="100%" display="flex" flexDirection="column">
      <Header
        defaultAddress={userLocation?.address}
        concepts={data?.concepts}
        onChange={handleConceptsChange}
        onSelect={handleGeolocation}
        value={selectedConceptsUuids}
      />
      {userLocation?.coords ? (
        <Body
          coords={userLocation.coords}
          isError={isError}
          isLoading={isLoading}
          kitchenInfos={filteredKitchens}
          selectedConceptsUuids={selectedConceptsUuids}
        />
      ) : (
        <VStack flex={1} gap={6} justify="center" align="center">
          <UserLocationButton onGeolocation={handleUserPosition} />
          <Text fontSize="xs" fontWeight={300} color="gray.600" textAlign="center">
            <Trans>Enter your location to find stores near you </Trans>
          </Text>
        </VStack>
      )}
    </Container>
  );
};
