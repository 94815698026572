import { Button } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCart } from '@/contexts/cart';
import { useOnSiteSession } from '@/contexts/onSiteSession';
export interface KioskAbandonCartButtonProps {
  sx?: CSSProperties;
}

export const KioskAbandonCartButton = ({ sx }: KioskAbandonCartButtonProps) => {
  const navigate = useNavigate();
  const [locationUuid] = useLocalStorage('LOCATION_UUID', null);

  const { resetCart } = useCart();
  const { endOnSiteSession } = useOnSiteSession();

  const handleAbandonClick = () => {
    endOnSiteSession({
      sessionEndReason: 'abandon',
    });

    resetCart();
    navigate(`/onSite/${locationUuid}`);
  };

  return (
    <Button
      sx={sx}
      color="gray.700"
      textTransform="uppercase"
      height={{ base: '40px', md: '80px' }}
      onClick={handleAbandonClick}
      variant="outline"
      border="1px solid #D0D5DD"
      fontSize={{ base: 16, md: 28 }}
      boxShadow="0px 1px 2px 0px #1018280D"
    >
      <Trans>Abandon cart</Trans>
    </Button>
  );
};
