import { Card, CardBody, Flex } from '@chakra-ui/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { sortBy } from 'lodash-es';
import { useMemo } from 'react';

import { Coords, KitchenInfos } from '@/api/types';
import { RestaurantCard } from '@/components/RestaurantCard/RestaurantCard';

import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';

type LocationDetailsProps = {
  coords: Coords;
  kitchenInfos: KitchenInfos;
  selectedConceptUuids: string[];
};

export const KitchenGeolocationCard = ({ coords, selectedConceptUuids, kitchenInfos }: LocationDetailsProps) => {
  const [wrapper] = useAutoAnimate();
  const { address, distance, menuInfos, label } = kitchenInfos;

  const sortedMenuInfos = sortBy(menuInfos, 'label');
  const kitchenConcepts = sortedMenuInfos.map(({ conceptLogo, conceptUuid, label }) => ({
    label,
    logo: conceptLogo,
    uuid: conceptUuid,
  }));
  const googleMapUrl = useMemo(() => {
    if (selectedConceptUuids.length === 1 && sortedMenuInfos[0].googleMapsUrl) {
      return sortedMenuInfos[0].googleMapsUrl;
    }

    const { latitude, longitude } = coords;
    return encodeURI(`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${address}`);
  }, [address, coords, selectedConceptUuids, sortedMenuInfos]);

  return (
    <Card boxShadow="unset" borderRadius={8}>
      <CardHeader address={address} distance={distance} addressUrl={googleMapUrl} kitchenLabel={label} />
      <CardBody paddingBlock={0}>
        <Flex ref={wrapper} direction="row" flexWrap="nowrap" overflow="auto" gap={4}>
          {sortedMenuInfos
            .filter(({ conceptUuid }) => selectedConceptUuids.includes(conceptUuid))
            .map((menuInfo) => (
              <RestaurantCard
                compact
                flexBasis={300}
                flexGrow={1}
                flexShrink={0}
                key={menuInfo.conceptUuid}
                menuInfo={menuInfo}
              />
            ))}
        </Flex>
      </CardBody>
      <CardFooter concepts={kitchenConcepts} />
    </Card>
  );
};
