import { t } from '@lingui/macro';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Shift } from '@/api/types';

const useOpeningHours = (shifts: Shift[], opensAt?: string) => {
  const isOpenToday = useMemo(() => shifts.length > 0, [shifts]);

  const isOpenNow = useMemo(() => {
    if (!shifts.length) return false;

    return !shifts[0].shiftStart;
  }, [shifts]);

  const parsedOpensAt = useMemo(() => {
    if (opensAt) return dayjs(opensAt);
  }, [opensAt]);

  const closesAt = useMemo(() => {
    if (!shifts.length) return;

    return dayjs(shifts[0].shiftEnd);
  }, [shifts]);

  const openingLabel = useMemo(() => {
    if (isOpenNow && closesAt) {
      return t`Open until ${closesAt.format('LT')}`;
    } else if (isOpenToday && parsedOpensAt) {
      return t`Opens at ${parsedOpensAt.format('LT')}`;
    }
  }, [isOpenNow, closesAt, isOpenToday, parsedOpensAt]);

  return {
    isOpenNow,
    closesAt,
    isOpenToday,
    opensAt: parsedOpensAt,
    shifts,
    openingLabel,
  };
};

export default useOpeningHours;
