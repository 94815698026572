import { Box, Button, Flex, Heading, List, ListItem } from '@chakra-ui/react';

import type { Category, MenuElement } from '@/api/types';
import MenuItem from '@/components/MenuItem';

interface Props {
  category: Category;
  menuElements: MenuElement[];
  currencyCode: string;
  onItemClick: (item: MenuElement) => void;
}

const ListBorderBottom = '0.5px solid #cacfd8';

const MenuCategory = ({ category, menuElements, currencyCode, onItemClick }: Props) => {
  return (
    <Flex justifyContent="space-between" w="100%" direction="column">
      <Flex as="header" borderBottom={ListBorderBottom} pb={4}>
        <Heading as="h2" fontSize="xl" fontWeight="600">
          {category.label}
        </Heading>
      </Flex>
      <Box as="section">
        <List>
          {menuElements.map((menuElement, index) => (
            <ListItem
              key={menuElement.menuElementUuid}
              py={3}
              borderBottom={index < menuElements.length - 1 ? ListBorderBottom : ''}
            >
              <Flex align="center">
                <Button
                  sx={{ all: 'unset', flex: '1' }}
                  _hover={{ all: 'unset', cursor: 'pointer', flex: '1' }}
                  _active={{ all: 'unset', flex: '1' }}
                  isDisabled={!menuElement.available}
                  _disabled={{ all: 'unset', _hover: { cursor: 'not-allowed' } }}
                  onClick={() => onItemClick(menuElement)}
                >
                  <MenuItem menuItem={menuElement} currencyCode={currencyCode} />
                </Button>
              </Flex>
            </ListItem>
          ))}
        </List>
      </Box>
    </Flex>
  );
};

export default MenuCategory;
