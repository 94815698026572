import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, Text } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
  url?: string;
  label?: string;
  className?: string;
  sx?: CSSProperties;
  onClick?: () => void;
}

export const BackButton = ({ url, label, className, sx, onClick }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      url ? navigate(url) : navigate(-1);
    }
  };

  return (
    <Button p={0} variant="ghost" onClick={handleClick} className={className} sx={sx}>
      <ArrowBackIcon w={6} h={6} color="gray.600" />
      {!!label && (
        <Text fontSize={18} fontWeight={500} color="gray.600" ml={2}>
          {label}
        </Text>
      )}
    </Button>
  );
};
