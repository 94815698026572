import { useMemo } from 'react';

import { useUserLocationsQuery } from '@/api/gateway-click-collect';
import { useInternUserLocationsQuery } from '@/api/useInternUserLocationsQuery';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

export const useInternalLocationList = () => {
  const { isKioskUser } = useOnSiteInfo();

  const {
    data: gatewayLocations,
    isLoading: areGatewayLocationsLoading,
    isError: areGatewayLocationsOnError,
    isSuccess: areGatewayLocationsOnSuccess,
  } = useUserLocationsQuery({
    options: {
      enabled: isKioskUser,
    },
  });

  const {
    data: locations,
    isLoading: areCustomerOrderLocationsLoading,
    isError: areCustomerOrderLocationsOnError,
    isSuccess: areCustomerOrderLocationsOnSuccess,
  } = useInternUserLocationsQuery({
    options: {
      enabled: !isKioskUser,
    },
  });

  const locationsList = useMemo(() => {
    const usedLocations = isKioskUser
      ? gatewayLocations
      : locations?.map((location) => ({ ...location, kitchenLabel: location.label }));

    if (!usedLocations) return [];

    return usedLocations;
  }, [gatewayLocations, isKioskUser, locations]);

  const areLocationsLoading = useMemo(
    () => (areGatewayLocationsLoading && isKioskUser) || (areCustomerOrderLocationsLoading && !isKioskUser),
    [areGatewayLocationsLoading, isKioskUser, areCustomerOrderLocationsLoading]
  );

  const areLocationsOnError = useMemo(
    () => (areGatewayLocationsOnError && isKioskUser) || (areCustomerOrderLocationsOnError && !isKioskUser),
    [areGatewayLocationsOnError, areCustomerOrderLocationsOnError, isKioskUser]
  );

  return {
    areLocationsOnError,
    locationsList,
    areLocationsLoading,
    isSuccess: areCustomerOrderLocationsOnSuccess || areGatewayLocationsOnSuccess,
  };
};
