import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { locationsKeys } from './locations.helpers';
import { UserLocation } from './types';

interface HookProps<TData> {
  options?: Omit<
    UseQueryOptions<UserLocation[], ApiError, TData, ReturnType<(typeof locationsKeys)['listUserLocations']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useUserLocationsQuery = <TData = UserLocation[]>({ options = {} }: HookProps<TData>) => {
  const {
    gatewayClickCollect: { locationsApi },
  } = useTasterApis();

  return useQuery(locationsKeys.listUserLocations(), () => locationsApi.getUserLocations(), options);
};
