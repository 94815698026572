import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

import { ConceptInformation, useConceptsConfigurationQuery } from '@/api/gateway-click-collect';
import {
  Menu,
  Restaurant,
  useRestaurantMenuQuery,
  useRestaurantOutOfStocksQuery,
  useRestaurantQuery,
} from '@/api/gateway-click-collect/restaurants';
import { InactiveUserModal } from '@/components/InactiveUserModal/InactiveUserModal';
import PageLoader from '@/components/PageLoader';
import { HOUR_MS, MINUTE_MS } from '@/constants';
import { useCart } from '@/contexts/cart';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';
import { usePollingTerminalStatus } from '@/hooks/usePollingTerminalStatus';
import NotFoundPage from '@/routes/NotFoundPage';

export interface MenuDataContext {
  conceptInformation: ConceptInformation;
  menu: Menu;
  outOfStocks: Set<string>;
  restaurant: Restaurant;
}

export const useMenuDataContext = () => useOutletContext<MenuDataContext>();

export const MenuDataProvider = () => {
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };
  const { hasPairedTerminal } = useOnSiteInfo();
  const [, setCurrencyCode] = useLocalStorage('CURRENCY_CODE', '');
  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');
  const { cart, resetCart } = useCart();

  usePollingTerminalStatus({
    locationUuid,
    terminalUuid,
    maxTry: 5,
  });

  useEffect(() => {
    if (cart.restaurantPlatformId !== restaurantPlatformId) {
      resetCart();
    }
  }, [cart, resetCart, restaurantPlatformId]);

  const { data: conceptConfigs, isLoading: isLoadingConceptConfigs } = useConceptsConfigurationQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: HOUR_MS * 3,
    },
  });

  const { data: restaurant, isLoading: isLoadingRestaurant } = useRestaurantQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: MINUTE_MS * 5,
    },
    requestParams: {
      restaurantPlatformId,
      withOpeningInformation: true,
    },
  });

  const { data: menu, isLoading: isLoadingOutOfStocks } = useRestaurantMenuQuery({
    options: { cacheTime: Infinity, refetchInterval: MINUTE_MS * 5 },
    requestParams: { restaurantPlatformId },
  });

  const { data: outOfStocks, isLoading: isLoadingMenu } = useRestaurantOutOfStocksQuery({
    options: { cacheTime: Infinity, refetchInterval: MINUTE_MS * 5, select: (response) => new Set(response) },
    requestParams: { restaurantPlatformId },
  });

  const conceptInformation = useMemo(() => {
    if (conceptConfigs && restaurant) {
      return conceptConfigs.concepts.find(({ uuid }) => uuid === restaurant.conceptUuid);
    }
  }, [conceptConfigs, restaurant]);

  // TODO remove from localStorage
  useEffect(() => {
    if (restaurant?.currencyCode) {
      setCurrencyCode(restaurant.currencyCode);
    }
  }, [restaurant, setCurrencyCode]);

  const isLoading = isLoadingConceptConfigs || isLoadingRestaurant || isLoadingMenu || isLoadingOutOfStocks;

  if (!hasPairedTerminal) {
    return <Navigate to="/onSite/terminalDisconnected" />;
  }

  if (conceptInformation && menu && outOfStocks && restaurant)
    return (
      <>
        <Outlet
          context={
            {
              conceptInformation,
              menu,
              outOfStocks,
              restaurant,
            } satisfies MenuDataContext
          }
        />
        <InactiveUserModal locationUuid={locationUuid} />
      </>
    );

  if (isLoading) {
    return <PageLoader />;
  }

  return <NotFoundPage to={`/onSite/${locationUuid}`} />;
};
