import { Box, Button, RadioProps, Stack, useRadio, useRadioGroup } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Dayjs } from 'dayjs';
import { useState } from 'react';

function TimeOption(props: RadioProps) {
  const { getCheckboxProps, getInputProps } = useRadio(props);
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();

  return (
    <Box as="label" cursor="pointer">
      <input {...inputProps} />
      <Box
        {...checkboxProps}
        textAlign="center"
        _checked={{
          backgroundColor: '#EEEEEE',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

interface Props {
  isVisible: boolean;
  onConfirm: (value: Dayjs) => void;
  preOrderTimes: Dayjs[];
}

function PickupTimeSelector({ isVisible, onConfirm, preOrderTimes }: Props) {
  const [pickupTimeIndex, setPickupTimeIndex] = useState<string | undefined>(undefined);

  const { getRadioProps, getRootProps } = useRadioGroup({
    onChange: (newPickupTimeIndex) => {
      setPickupTimeIndex(newPickupTimeIndex);
    },
    value: pickupTimeIndex,
  });

  const radioGroupProps = getRootProps();

  const formatPickupTimeOption = (time: Dayjs): string => time.format('LT');

  const handleConfirm = () => {
    if (pickupTimeIndex !== undefined) onConfirm(preOrderTimes[Number(pickupTimeIndex)]);
  };

  return (
    <Stack gap={2} hidden={!isVisible}>
      <Box borderWidth="2px" borderRadius="md" maxHeight={176} overflowY="auto" py={2}>
        <Stack gap={2} {...radioGroupProps} mb={4}>
          {preOrderTimes.map((value, index) => {
            const radioProps = getRadioProps({ value: index.toString() });

            return (
              <TimeOption {...radioProps} key={index}>
                {formatPickupTimeOption(value)}
              </TimeOption>
            );
          })}
        </Stack>
      </Box>
      <Button width="100%" isDisabled={pickupTimeIndex === undefined} onClick={handleConfirm}>
        <Trans>Confirm</Trans>
      </Button>
    </Stack>
  );
}

export default PickupTimeSelector;
