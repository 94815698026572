import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';

import { fetchDeliveryPlatform } from './api';
import { queryKey } from './queryKeyFactory';
import { DeliveryPlatform } from './types';

interface HookProps<TData> {
  locationUuid: string;
  options?: Omit<
    UseQueryOptions<DeliveryPlatform, ApiError, TData, ReturnType<(typeof queryKey)['deliveryPlatform']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useDeliveryPlatformQuery = <TData = DeliveryPlatform>({ locationUuid, options }: HookProps<TData>) =>
  useQuery(queryKey.deliveryPlatform(locationUuid), () => fetchDeliveryPlatform(locationUuid), options);
