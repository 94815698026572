import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

const BluredOverlay = forwardRef<BoxProps, 'div'>((props, ref) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      p={2}
      color="white"
      bg="blackAlpha.600"
      backdropFilter="auto"
      backdropBlur="2px"
      pos="absolute"
      bottom="0"
      left="0"
      right="0"
      top="0"
      {...props}
      ref={ref}
    >
      {props.children}
    </Box>
  );
});

export default BluredOverlay;
