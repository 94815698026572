import { Box, Flex, List, ListItem, SlideFade, Stack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';

import type { Modifier, ModifierGroup } from '@/api/types';
import NumberInput from '@/components/NumberInput';
import { hasMenuItemCalories } from '@/helpers/menu.helpers';
import useFormatPrice from '@/hooks/useFormatPrice';
import type { ModifierCart } from '@/types';

import ModifierGroupInput from './ModifierGroupInputs';

interface Props {
  currencyCode: string;
  max: number;
  min: number;
  modifiers: Modifier[];
  selectedModifiers: ModifierCart[];
  isDisabled: boolean;
  onChange: (modifierUuid: string, quantity: number) => void;
  onNestedChange: (modifierUuid: string, modifierGroup: ModifierGroup, selectedModifiers: ModifierCart[]) => void;
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  align-items: center;
  gap: 4px;
`;

const ModifiersSelectGroup = ({
  currencyCode,
  max,
  min,
  modifiers,
  selectedModifiers,
  isDisabled,
  onChange,
  onNestedChange,
}: Props) => {
  const { formatPrice } = useFormatPrice();

  const [modifierQuantity, setModifierQuantity] = useState<number>(() => {
    return selectedModifiers.reduce((count, modifier) => (modifier.quantity ?? 1) + count, 0);
  });

  useEffect(() => {
    if (selectedModifiers) {
      setModifierQuantity(selectedModifiers.reduce((count, modifier) => (modifier.quantity ?? 1) + count, 0));
    }
  }, [selectedModifiers]);

  const onIncrementModifier = (modifierUuid: string, quantity: number) => {
    if (onChange) {
      onChange(modifierUuid, quantity);
    }
  };

  const onDecrementModifier = (modifierUuid: string, quantity: number) => {
    if (onChange) {
      onChange(modifierUuid, quantity);
    }
  };

  return (
    <div>
      <Stack gap={2}>
        {modifiers.map((modifier) => {
          const foundSelectedModifier = selectedModifiers.find(
            ({ modifierUuid }) => modifierUuid === modifier.modifierUuid
          );

          let quantity = 0;
          if (foundSelectedModifier && foundSelectedModifier.quantity) {
            quantity = foundSelectedModifier.quantity;
          }

          const formattedPrice = quantity ? `+ ${formatPrice(modifier.sellingPrice * quantity, currencyCode)}` : '';

          return (
            <Box color={modifier.available ? '#02091D' : '#74767B'} key={modifier.modifierUuid}>
              <GridContainer>
                <Flex gap={1} alignItems="baseline">
                  <Text fontWeight="400" fontSize="md" flexWrap="nowrap">
                    {modifier.label}
                  </Text>
                  {hasMenuItemCalories(modifier) && (
                    <Text fontSize="sm" color="gray.500">
                      {modifier.nutritionalInfo.energyKcal}
                      <Trans>kcal</Trans>
                    </Text>
                  )}
                </Flex>
                <Text fontWeight="400" fontSize="sm">
                  {modifier.sellingPrice > 0 ? formattedPrice : ''}
                </Text>
                <NumberInput
                  min={0}
                  defaultValue={quantity}
                  isDisabled={modifierQuantity >= max || isDisabled || !modifier.available}
                  onIncrement={(quantity) => onIncrementModifier(modifier.modifierUuid, quantity)}
                  onDecrement={(quantity) => onDecrementModifier(modifier.modifierUuid, quantity)}
                />
              </GridContainer>
              <SlideFade offsetY="-20px" in={quantity > 0}>
                <List gap={4}>
                  {quantity &&
                    modifier.modifierGroups &&
                    modifier.modifierGroups.map((modifierGroup, index) => {
                      return (
                        <ListItem paddingLeft={4} mt={4} key={`${modifierGroup.modifierGroupUuid}-${index}`}>
                          <ModifierGroupInput
                            onUpdateSelectedModifierGroup={(modifierGroup, selectedModifier) =>
                              onNestedChange(modifier.modifierUuid, modifierGroup, selectedModifier)
                            }
                            currencyCode={currencyCode}
                            modifierGroup={modifierGroup}
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </SlideFade>
            </Box>
          );
        })}
      </Stack>
    </div>
  );
};

export default ModifiersSelectGroup;
