import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

import { t } from '@lingui/macro';
import dayjs, { Dayjs } from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);

let customFormatsLocalizedMap: Record<string, string> | undefined = {};

export const switchDayJsLocale = (locale: string) => {
  dayjs.locale(locale);
  dayjs.updateLocale(locale, {
    calendar: {
      sameDay: `[${t`Today at`}] LT`,
      nextDay: `[${t`Tomorrow at`}] LT`,
    },
  });

  customFormatsLocalizedMap = {
    fr: {
      sameDay: `[${t`Today`}], dddd D MMMM [${t`at`}] LT`,
      nextDay: `[${t`Tomorrow`}], dddd D MMMM [${t`at`}] LT`,
    },
    en: {
      sameDay: `[${t`Today`}], MMMM D [${t`at`}] LT`,
      nextDay: `[${t`Tomorrow`}], MMMM D [${t`at`}] LT`,
    },
    es: {
      sameDay: `[${t`Today`}], [el] dddd D [de] MMMM [${t`at`}] LT`,
      nextDay: `[${t`Tomorrow`}], [el] dddd D [de] MMMM [${t`at`}] LT`,
    },
    nl: {
      sameDay: `[${t`Today`}], dddd, MMMM D [${t`at`}] LT`,
      nextDay: `[${t`Tomorrow`}], dddd, MMMM D [${t`at`}] LT`,
    },
  }[locale];
};

export const getLocalCalendarFormat = (date: string | Dayjs) => dayjs(date).calendar(date, customFormatsLocalizedMap);
