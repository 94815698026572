import { Avatar, AvatarGroup, CardFooter as ChakraCardFooter, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { Concept } from '@/api/types';

type CardFooterProps = {
  concepts: Concept[];
};

export const CardFooter = ({ concepts }: CardFooterProps) => {
  return (
    <ChakraCardFooter flexDirection="column" gap={1}>
      <Text fontSize="xs" color="gray.500" fontWeight={300}>
        <Trans>In this location</Trans>
      </Text>
      <AvatarGroup spacing={-4}>
        {concepts.map(({ label, logo, uuid }) => (
          <Avatar key={uuid} name={label} src={logo} showBorder bg="white" borderColor="gray.100" borderWidth={1} />
        ))}
      </AvatarGroup>
    </ChakraCardFooter>
  );
};
