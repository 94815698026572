import { keyBy } from 'lodash-es';

import { ConceptInformation } from '@/api/gateway-click-collect/concepts';
import { Restaurant } from '@/api/gateway-click-collect/restaurants';

export interface ConceptRestaurant
  extends Pick<Restaurant, 'restaurantPlatformId'>,
    Pick<ConceptInformation, 'conceptName' | 'tags'> {
  heroPicture?: string;
  logoPicture?: string;
}

export const formatRestaurants = ({
  conceptsInformation,
  restaurants,
}: {
  conceptsInformation?: ConceptInformation[];
  restaurants?: Restaurant[];
}): ConceptRestaurant[] | null => {
  if (!restaurants || !conceptsInformation) {
    return null;
  }

  const conceptsInformationByUuid = keyBy(conceptsInformation, 'uuid');

  return restaurants
    .filter(({ status }) => status === 'open')
    .map<ConceptRestaurant | undefined>(({ conceptUuid, restaurantPlatformId }) => {
      const concept = conceptsInformationByUuid[conceptUuid];

      if (concept) {
        const { tags, conceptName, heroPictures, logoPictures } = concept;

        return {
          conceptName,
          heroPicture: heroPictures.find(({ width }) => width === 768)?.url,
          logoPicture: logoPictures[0]?.url,
          restaurantPlatformId,
          tags,
        };
      }
    })
    .filter((conceptRestaurant): conceptRestaurant is ConceptRestaurant => !!conceptRestaurant);
};
