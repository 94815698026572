import { useLocalStorage } from '@uidotdev/usehooks';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { ConceptInformation } from '@/api/gateway-click-collect/concepts';
import { Category, Restaurant } from '@/api/gateway-click-collect/restaurants';
import { MenuElement } from '@/api/types';
import { useMenuDataContext } from '@/contexts/MenuDataProvider';
import NotFoundPage from '@/routes/NotFoundPage';
import { getCategoryMenuElements } from '@/routes/PayOnSite/MenuItemsPage/MenuItemsPage.helpers';

export interface CategoryDataContext {
  category: Category;
  conceptInformation: ConceptInformation;
  menuElements: MenuElement[];
  restaurant: Restaurant;
}

export const useCategoryDataContext = () => useOutletContext<CategoryDataContext>();

export const CategoryDataProvider = () => {
  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');
  const { categoryUuid } = useParams() as { categoryUuid: string; restaurantPlatformId: string };
  const { conceptInformation, menu, outOfStocks, restaurant } = useMenuDataContext();

  const category = menu.categories?.find(({ uuid }) => uuid === categoryUuid);
  const menuElements = getCategoryMenuElements({ category, menu, outOfStocks });

  if (category && menuElements) {
    return (
      <Outlet
        context={
          {
            category,
            conceptInformation,
            menuElements,
            restaurant,
          } satisfies CategoryDataContext
        }
      />
    );
  }

  return <NotFoundPage to={`/onSite/${locationUuid}`} />;
};
