import { Coords, GetTerminalStatusMutation } from '@/api/types';

export const queryKey = {
  deliveryPlatform: (locationUuid: string) => ['deliveryPlatform', locationUuid] as const,
  getKitchenInfos: (locationUuid: string) => ['getKitchenInfos', locationUuid] as const,
  kitchens: (requestParams: Coords) => ['kitchens', requestParams] as const,
  internUserLocations: ['internUserLocations'] as const,
  terminalStatus: (requestParams?: GetTerminalStatusMutation) => ['terminalStatus', requestParams] as const,
  order: (requestParams?: string) => ['order', requestParams] as const,
};
