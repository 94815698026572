import * as Sentry from '@sentry/react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { CategoriesDataProvider } from '@/contexts/CategoriesDataProvider';
import { KioskDataProvider } from '@/contexts/KioskDataProvider';
import { MenuDataProvider } from '@/contexts/MenuDataProvider';
import { CartPage } from '@/routes/CartPage';
import CheckoutPage from '@/routes/CheckoutPage';
import ConfirmPaymentPage from '@/routes/ConfirmPaymentPage';
import HomePage from '@/routes/HomePage';
import { LocationFinder } from '@/routes/LocationFinder';
import MenuPage from '@/routes/MenuPage';
import OrderStatusPage from '@/routes/OrderStatusPage';
import { CategoryDataProvider } from '@/routes/PayOnSite/MenuItemsPage/CategoryDataProvider';
import { RestaurantsPage } from '@/routes/RestaurantsPage';

import { ProtectedRoute } from './components/ProtectedRoute';
import { useOnSiteInfo } from './hooks/useOnSiteInfo';
import { FetchKioskDataLayout } from './layouts/FetchKioskDataLayout';
import { SelectRestaurantDataLayout } from './layouts/SelectRestaurantKioskDataLayout';
import NotFoundPage from './routes/NotFoundPage';
import { CustomerNamePage } from './routes/PayOnSite/CustomerNamePage';
import { InternalLocationsPage } from './routes/PayOnSite/InternalLocationsPage';
import { MenuCategoriesPage } from './routes/PayOnSite/MenuCategoriesPage';
import { MenuItemsPage } from './routes/PayOnSite/MenuItemsPage';
import { OrderConfirmedPage } from './routes/PayOnSite/OrderConfirmedPage';
import { PairTerminalPage } from './routes/PayOnSite/PairTerminalPage';
import { PaymentInstructionsPage } from './routes/PayOnSite/PaymentInstructionsPage';
import { TerminalSettingsPage } from './routes/PayOnSite/TerminalSettingsPage';
import { TerminalDisconnectedPage } from './routes/TerminalDisconnected';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter = () => {
  const { hasPairedTerminal, isKioskUser } = useOnSiteInfo();

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <SentryRoutes>
          {/* Online flow router */}
          <Route element={<FetchKioskDataLayout />}>
            <Route path="/:locationUuid" element={<HomePage />} />
          </Route>
          <Route
            path="/:conceptUuids?"
            element={hasPairedTerminal ? <Navigate to="/onSite" replace /> : <LocationFinder />}
          />
          <Route path="/menu/:restaurantPlatformId/:cityName?/:extraInfo?" element={<MenuPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/confirmPayment" element={<ConfirmPaymentPage />} />
          <Route path="/orderStatus/:orderUuid" element={<OrderStatusPage />} />
          <Route path="/notFound" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />

          {/* OnSite flow router */}
          <Route path="/onSite" element={<ProtectedRoute />}>
            <Route
              index
              element={hasPairedTerminal ? <Navigate to="terminalSettings" replace /> : <InternalLocationsPage />}
            />
            <Route path="/onSite/internalLocations" element={<InternalLocationsPage />} />
            <Route path="/onSite/pairTerminal" element={<PairTerminalPage />} />
            <Route path="/onSite/terminalSettings" element={<TerminalSettingsPage />} />
            {isKioskUser ? (
              <>
                <Route element={<KioskDataProvider />}>
                  <Route path="/onSite/:locationUuid" element={<RestaurantsPage />} />
                </Route>
                <Route element={<MenuDataProvider />}>
                  <Route element={<CategoriesDataProvider />}>
                    <Route path="/onSite/menu/:restaurantPlatformId" element={<MenuCategoriesPage />} />
                  </Route>
                  <Route element={<CategoryDataProvider />}>
                    <Route path="/onSite/menu/:restaurantPlatformId/:categoryUuid" element={<MenuItemsPage />} />
                  </Route>
                  <Route path="/onSite/menu/:restaurantPlatformId/cart" element={<CartPage />} />
                </Route>
              </>
            ) : (
              <Route element={<FetchKioskDataLayout />}>
                <Route path="/onSite/:locationUuid" element={<HomePage />} />
                <Route element={<SelectRestaurantDataLayout />}>
                  <Route path="/onSite/menu/:restaurantPlatformId" element={<MenuPage />} />
                </Route>
              </Route>
            )}
            <Route path="/onSite/customerName" element={<CustomerNamePage />} />
            <Route path="/onSite/terminalDisconnected" element={<TerminalDisconnectedPage />} />
            <Route path="/onSite/paymentInstructions/:orderUuid/:customerName" element={<PaymentInstructionsPage />} />
            <Route path="/onSite/checkout" element={<CheckoutPage />} />
            <Route path="/onSite/confirmPayment" element={<ConfirmPaymentPage />} />
            <Route path="/onSite/orderConfirmed/:oderUuid/:customerName" element={<OrderConfirmedPage />} />
            <Route path="/onSite/notFound" element={<NotFoundPage />} />
            <Route path="/onSite/*" element={<NotFoundPage />} />
          </Route>
        </SentryRoutes>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
