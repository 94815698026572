import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Button,
  chakra,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { Navigate } from 'react-router-dom';
import { object, string } from 'yup';

import { useSendCustomerEmailMutation } from '@/api/useSendCustomerEmailMutation';
import QrCodeArrow from '@/assets/img/qr_code_arrow.svg';
import footerLogo from '@/assets/img/taster_footer.png';
import { NextCustomerProgressBar } from '@/components/NextCustomerProgressBar';
import { useOrder } from '@/contexts/order';

import { PairDeviceLayout } from './PairDeviceLayout';

const StyledButton = chakra(Button, {
  baseStyle: {
    fontSize: '24',
    paddingY: '22px',
  },
});

export const OrderConfirmedPage = () => {
  const { order } = useOrder();

  const [isMobile] = useMediaQuery('(max-width: 600px)');

  const { isLoading, mutate: sendReceipt, isError, isSuccess } = useSendCustomerEmailMutation();

  const [paymentUuid] = useLocalStorage('PAYMENT_UUID', null);

  const initialOrderData = useRef(order);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(object({ email: string().email() })),
  });

  //TODO ref is used to store initial order and avoid 404 redirection when order is set to null before redirection in NexCustomerProgressBar component

  if (!initialOrderData.current) {
    return <Navigate to={`/onSite/notFound`} />;
  }

  const confirmedOrder = initialOrderData.current;

  const submitReceiptForm = handleSubmit((formData) => {
    const { email } = formData;

    if (!!paymentUuid && !!email) sendReceipt({ customerEmail: email, orderUuid: confirmedOrder.uuid, paymentUuid });
  });

  const orderQrCodeUrl = `${import.meta.env.VITE_ORDER_QR_CODE}${confirmedOrder.uuid}`;

  return (
    <PairDeviceLayout headerTitle={t`Order confirmed`}>
      <Stack px={2} gap={6} alignItems="center">
        <Stack alignItems="center" gap={2}>
          <Heading fontSize={64} fontWeight={700}>
            # {confirmedOrder.number}
          </Heading>
          <Heading fontSize={32} fontWeight={700} textAlign="center">
            <Trans>Thanks for your order! Track it here</Trans>
          </Heading>
        </Stack>
        <Flex justifyContent="center" padding={'46px 0 88px 0'} position="relative">
          <QRCode value={orderQrCodeUrl} size={200} />
          {!isMobile && (
            <Image
              src={QrCodeArrow}
              sx={{
                position: 'absolute',
                transform: 'translateX(120%) translateY(-25%)',
              }}
            />
          )}
        </Flex>
        {!!paymentUuid && (
          <Stack px={2} gap={2} alignItems="center">
            <Text fontSize={32} fontWeight={700}>
              <Trans>Want a receipt?</Trans>
            </Text>
            <form onSubmit={submitReceiptForm}>
              <Flex flexWrap="wrap" gap={2} justifyContent="center" alignItems="baseline">
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { invalid, isTouched } }) => (
                    <FormControl width="fit-content" isRequired isInvalid={invalid}>
                      <InputGroup>
                        <Input
                          {...field}
                          borderColor={!!invalid && isTouched ? 'red.error.main' : 'gray.300'}
                          placeholder={t`Type your email`}
                          maxWidth={450}
                          width="100%"
                        />
                        <InputRightElement>{isLoading && <Spinner size="xs" />}</InputRightElement>
                      </InputGroup>
                      <FormHelperText textAlign="center">
                        <Text color={isError ? 'red.error.main' : 'gray.600'} fontWeight={300}>
                          {isError ? (
                            <Trans>There was an error on our side</Trans>
                          ) : (
                            <Trans>Paperless for the planet 💚</Trans>
                          )}
                        </Text>
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                <StyledButton onClick={submitReceiptForm} isDisabled={!isValid || isLoading}>
                  <Text>
                    <Trans>Submit</Trans>
                  </Text>
                </StyledButton>
                {isSuccess && !isLoading && (
                  <Flex
                    width="100%"
                    maxW="350px"
                    borderRadius={12}
                    padding={2}
                    background="green.success.25"
                    gap={3}
                    border="1px solid"
                    borderColor="green.success.300"
                    alignItems="center"
                  >
                    <CheckCircleIcon color="green.success.300" />
                    <Text fontSize={14} fontWeight={600} color="green.success.700">
                      <Trans>Receipt sent</Trans>
                    </Text>
                  </Flex>
                )}
              </Flex>
            </form>
          </Stack>
        )}
        <NextCustomerProgressBar locationUuid={confirmedOrder.location_uuid} />
      </Stack>
      <Flex color="gray.disabled" justifyContent="center" alignItems="center" left={0} right={0} marginTop={16}>
        <Image src={footerLogo} alt="Taster" width={420} />
      </Flex>
    </PairDeviceLayout>
  );
};
