import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';

import { fetchKitchens } from './api';
import { queryKey } from './queryKeyFactory';
import { Coords, Kitchens } from './types';

interface HookProps<TData> {
  requestParams: Coords;
  options?: Omit<
    UseQueryOptions<Kitchens, ApiError, TData, ReturnType<(typeof queryKey)['kitchens']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useKitchensQuery = <TData = Kitchens>({ requestParams, options }: HookProps<TData>) =>
  useQuery(queryKey.kitchens(requestParams), () => fetchKitchens(requestParams), options);
