import { Button, Image, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';

import TasterLogo from '@/assets/img/taster_circle_logo.svg';
import { NextCustomerProgressBar } from '@/components/NextCustomerProgressBar';

const eventTypes = ['click', 'keypress', 'mousemove', 'mousedown', 'scroll', 'touchmove', 'pointermove'];

const addEventListeners = (listener: EventListener) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false);
  });
};

const removeEventListeners = (listener: EventListener) => {
  eventTypes.forEach((type) => {
    window.removeEventListener(type, listener, false);
  });
};

export const InactiveUserModal = ({ locationUuid }: { locationUuid: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const startTimeOut = () =>
      setTimeout(() => {
        setIsOpen(true);
      }, 180000);

    const resetOpeningTimeout = () => {
      if (!isOpen) {
        clearTimeout(openModalTimeOut);
        openModalTimeOut = startTimeOut();
      }
    };

    let openModalTimeOut = startTimeOut();
    addEventListeners(resetOpeningTimeout);

    return () => {
      removeEventListeners(resetOpeningTimeout);
      clearTimeout(openModalTimeOut);
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent width="100%" maxW="calc(100% - 3rem)" maxHeight="100%" my="auto">
        <ModalBody py="12" px="28">
          <Stack alignItems="center" width="100%" gap={134}>
            <Stack gap={2} alignItems="center">
              <Image src={TasterLogo} w={120} />
              <Text fontSize={48} fontWeight={700}>
                <Trans>You still there?</Trans>
              </Text>
            </Stack>
            <Stack gap={3} width="100%" maxWidth={482}>
              <Button width="100%" h={120} onClick={() => setIsOpen(false)}>
                <Text fontSize={32}>
                  <Trans>Continue ordering</Trans>
                </Text>
              </Button>
              <NextCustomerProgressBar sx={{ height: 120, width: '100%' }} locationUuid={locationUuid} />
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
