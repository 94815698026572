import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  SystemStyleObject,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useMemo } from 'react';
import { FaChevronUp, FaClock, FaCopy, FaInfoCircle, FaMapMarkerAlt } from 'react-icons/fa';

import { Shift } from '@/api/types';
import { useDeliveryPlatformQuery } from '@/api/useDeliveryPlatformQuery';
import nf525Logo from '@/assets/img/logo-nf-525.png';
import { DeliveryPlatformInfo } from '@/components/DeliveryPlatformInfo';
import PageLoader from '@/components/PageLoader';
import type { ResponsivePropertyDrawer } from '@/types';

import { RestaurantInfoShift } from './RestaurantInfoShift';

interface RestaurantInfoProps {
  locationUuid: string;
  conceptUuid: string;
  kitchenLabel: string;
  description: string;
  restaurantName: string;
  kitchenAddress: string;
  openingHours: Record<string, Shift[]>;
  isRestaurantOpen: boolean;
  openingLabel?: string | null;
  sx?: SystemStyleObject;
}

export const RestaurantInfo = ({
  locationUuid,
  kitchenLabel,
  conceptUuid,
  description,
  restaurantName,
  kitchenAddress,
  openingHours,
  openingLabel,
  sx,
}: RestaurantInfoProps) => {
  const { isOpen: isDrawerOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isHoursOpen, onToggle } = useDisclosure();
  const openingHoursList = useMemo(() => Object.keys(openingHours ?? {}).sort(), [openingHours]);

  const copyAddressToClipboard = () => {
    return navigator.clipboard.writeText(kitchenAddress);
  };

  const responsiveDrawerPlacement = useBreakpointValue<'bottom' | 'right'>({ base: 'bottom', md: 'right' });

  const responsiveDrawerSize = useBreakpointValue<'xl' | 'md'>({ base: 'xl', md: 'md' });

  const responsiveDrawerProperties = useBreakpointValue<ResponsivePropertyDrawer>({
    base: { height: '80vh', borderTopRadius: '3xl' },
    md: { borderLeftRadius: 'none' },
  });

  const { data: deliveryPlatform, isLoading: isDeliveryPlatformLoading } = useDeliveryPlatformQuery({
    locationUuid: locationUuid,
  });

  const deliveryPlatformInfoList = useMemo(() => {
    if (!deliveryPlatform) return [];
    return deliveryPlatform[conceptUuid] ?? [];
  }, [deliveryPlatform, conceptUuid]);

  return (
    <>
      <Button onClick={onOpen} leftIcon={<FaInfoCircle />} sx={sx} variant="link">
        <Trans>More Info</Trans>
      </Button>
      <Drawer isOpen={isDrawerOpen} size={responsiveDrawerSize} placement={responsiveDrawerPlacement} onClose={onClose}>
        <DrawerOverlay />

        <DrawerContent {...responsiveDrawerProperties}>
          <DrawerHeader>
            <Center>
              <Heading as="h2" size={'lg'}>
                <Trans>Informations</Trans>
              </Heading>
            </Center>
            <DrawerCloseButton top={4} />
          </DrawerHeader>
          <Divider my={4} />
          <DrawerBody paddingBottom="150px">
            <Heading as="h3" size={'md'} textAlign={'center'} marginBottom={2}>
              {restaurantName}
            </Heading>
            <Text color={'gray.600'} marginBottom={2}>
              {description}
            </Text>
            <Divider my={4} />
            <Box display={'flex'} gap={2} alignItems={'center'} width="100%">
              <FaMapMarkerAlt />
              <Link href={encodeURI(`https://www.google.com/maps/search/?api=1&query=${kitchenAddress}`)}>
                {kitchenAddress}
              </Link>
              <IconButton
                icon={<FaCopy />}
                marginLeft={'auto'}
                aria-label={t`copy restaurant address`}
                onClick={copyAddressToClipboard}
                variant={'ghost'}
              />
            </Box>
            <Divider my={4} />
            <Button
              sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 0, width: '100%' }}
              aria-label={isHoursOpen ? t`open restaurant opening hours` : t`close restaurant opening hours`}
              onClick={onToggle}
              variant="ghost"
            >
              <Flex gap={2}>
                <FaClock />
                {openingLabel ? <Text>{openingLabel}</Text> : <Trans>This restaurant is currently closed.</Trans>}
              </Flex>
              {!!openingHoursList.length && (
                <FaChevronUp
                  style={{
                    transform: `rotate(${isHoursOpen ? '0' : '180deg'})`,
                    transition: 'transform .5s ease-in-out',
                  }}
                />
              )}
            </Button>
            <Collapse in={isHoursOpen} animateOpacity>
              <Stack gap={2}>
                {openingHoursList.map((day, index) => {
                  return <RestaurantInfoShift key={index} day={day} shiftsList={openingHours[day]} />;
                })}
              </Stack>
            </Collapse>
            <Divider my={4} />
            <div>
              {isDeliveryPlatformLoading ? (
                <PageLoader />
              ) : (
                <>
                  <Stack spacing={3}>
                    {deliveryPlatformInfoList.map(({ platformUuid, platformLabel, platformLogo, platformUrl }) => {
                      return (
                        <DeliveryPlatformInfo
                          key={platformUuid}
                          platformLabel={platformLabel}
                          platformLogo={platformLogo}
                          platformUrl={platformUrl}
                          kitchenLabel={kitchenLabel}
                        />
                      );
                    })}
                  </Stack>
                  {!!deliveryPlatformInfoList.length && <Divider my={4} />}
                </>
              )}
            </div>
            <HStack alignItems="center" gap={4}>
              <Image boxSize="90px" src={nf525Logo} alt="NF 525 logo" />
              <div>
                <Text>Certification NF525</Text>
                <Text color="gray.600" fontSize="sm">
                  Feedme, version 3
                </Text>
                <Text color="gray.600" fontSize="sm">
                  Certificat n°525/0576-1
                </Text>
                <Text color="gray.600" fontSize="sm">
                  Catégorie B
                </Text>
              </div>
            </HStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
