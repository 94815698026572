import { Box, Spinner } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { TerminalStatusEnum } from '@/api/types';
import { useDeliveryPlatformQuery } from '@/api/useDeliveryPlatformQuery';
import { useTerminalStatusMutation } from '@/api/useTerminalStatusMutation';
import CtaButton from '@/components/CtaButton';
import PageLoader from '@/components/PageLoader';
import { RestaurantsList } from '@/components/RestaurantsList';
import { TouchToStartModal } from '@/components/TouchToStartModal';
import WebviewMessage from '@/components/WebviewMessage';
import { useOnSiteSession } from '@/contexts/onSiteSession';
import { useOrder } from '@/contexts/order';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { useUpdateMetadata } from '@/hooks/useUpdateMetadata';
import AppLayout from '@/layouts/AppLayout';
import { useKioskDataLayout } from '@/layouts/FetchKioskDataLayout';

export default function HomePage() {
  const navigate = useNavigate();
  const params = useParams() as { locationUuid: string };
  const urlLocationUuid = params.locationUuid;
  const [searchParams] = useSearchParams();
  const isDelivery = !!searchParams.get('delivery');

  const { hasPairedTerminal, navigateAuthenticated, isKioskUser } = useOnSiteInfo();

  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');
  const [locationUuid, setLocationUuid] = useLocalStorage('LOCATION_UUID', '');

  useEffect(() => setLocationUuid(urlLocationUuid), [urlLocationUuid, setLocationUuid, locationUuid]);

  const { mutate: getTerminalStatus } = useTerminalStatusMutation({
    onError: () => {
      navigate('/onSite/terminalDisconnected');
    },
    onSuccess: (terminalStatus) => {
      if (terminalStatus.status !== TerminalStatusEnum.READY) {
        navigate('/onSite/terminalDisconnected');
      }
    },
  });

  const { isLoading, areRestaurantsOnError, restaurantsList, kitchenLabel } = useKioskDataLayout();

  useUpdateMetadata({ title: kitchenLabel });

  const { data: deliveryPlatform, isLoading: isDeliveryPlatformLoading } = useDeliveryPlatformQuery({
    locationUuid: urlLocationUuid,
    options: {
      enabled: !!urlLocationUuid && isDelivery,
    },
  });

  const { order, setOrder } = useOrder();

  if (
    order !== null &&
    (dayjs().diff(dayjs(order.placed_at), 'minute') >= 60 * 24 || ['initiated', 'delivered'].includes(order.status))
  ) {
    setOrder(null);
  }

  usePageViewTracker(kitchenLabel);

  const { startOnSiteSession } = useOnSiteSession();

  if (!urlLocationUuid || areRestaurantsOnError) return navigateAuthenticated('notFound');

  if ((isDeliveryPlatformLoading && isDelivery) || isLoading) {
    return <PageLoader />;
  }

  const handleStartClick = () => {
    if (isKioskUser) {
      startOnSiteSession({
        kitchenLabel,
        terminalUuid,
      });
    }

    getTerminalStatus({ locationUuid: urlLocationUuid, terminalUuid });
  };

  return (
    <>
      {hasPairedTerminal && <TouchToStartModal onClose={handleStartClick} />}
      <AppLayout
        title={t`Our restaurants`}
        hasPoweredBy
        maxWidthBreakpoints={{ lg: '900px' }}
        headerExtraContent={<WebviewMessage />}
        hasArrowBack={!hasPairedTerminal}
        hasSettingsButton={hasPairedTerminal}
        url={'/'}
      >
        <Box px={6} pt={8}>
          <RestaurantsList
            menuInfos={restaurantsList}
            deliveryPlatform={deliveryPlatform}
            isDelivery={isDelivery}
            locationUuid={urlLocationUuid}
          />
        </Box>
        {!!order && order.status !== 'delivered' && !hasPairedTerminal && (
          <CtaButton onClick={() => navigate(`/orderStatus/${order.uuid}`)}>
            <Spinner mr={2} />
            <Trans>Order in progress</Trans>
          </CtaButton>
        )}
      </AppLayout>
    </>
  );
}
