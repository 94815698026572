import { Button, ButtonProps, Image } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Link, To } from 'react-router-dom';

import CartIcon from '@/assets/img/shopping-cart.svg';

export interface KioskCartButtonProps extends ButtonProps {
  itemsQuantity: number;
  to: To;
}

export const KioskCartButton = ({ itemsQuantity, ...buttonProps }: KioskCartButtonProps) => (
  <Button
    as={Link}
    boxShadow="0px 1px 2px 0px #1018280D"
    fontSize={{ base: 16, md: 28 }}
    height={{ base: '40px', md: '80px' }}
    leftIcon={<Image src={CartIcon} />}
    textTransform="uppercase"
    {...buttonProps}
  >
    <Trans>Cart ({itemsQuantity})</Trans>
  </Button>
);
