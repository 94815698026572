import { CloseIcon } from '@chakra-ui/icons';
import { Button, Flex, Icon, Spinner, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { CSSProperties, useEffect, useMemo } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import { useDeleteOrderPaymentMutation } from '@/api/useDeleteOrderPaymentMutation';
import { useOrderQuery } from '@/api/useOrderQuery';
import terminalAnimation from '@/assets/anim/lottie_terminal.json';
import { BackButton } from '@/components/BackButton';
import { LottieAnimation } from '@/components/LottieAnimation';
import { useCart, useCartRequest } from '@/contexts/cart';
import { useOnSiteSession } from '@/contexts/onSiteSession';
import { useOrder } from '@/contexts/order';
import dataLayer from '@/helpers/dataLayer.helpers';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { PairDeviceLayout } from './PairDeviceLayout';

const BackButtonStyleProperties: CSSProperties = {
  top: 0,
  left: '16px',
  position: 'absolute',
  transform: 'translateY(-50%)',
};

const PaymentIndications = ({
  onCancelPaymentClick,
  onCancelOrderClick,
  isPaymentFailed,
}: {
  onCancelPaymentClick: () => void;
  onCancelOrderClick: () => void;
  isPaymentFailed: boolean;
}) => {
  return (
    <Stack px={2} alignItems="center" position="relative" width="100%" height="100%">
      <BackButton onClick={onCancelPaymentClick} label={t`Back`} sx={{ ...BackButtonStyleProperties }} />
      <Stack gap={12} alignItems="center">
        <LottieAnimation animation={terminalAnimation} sx={{ width: '250px' }} />
        <Stack gap={8}>
          <Text fontWeight={700} fontSize={32} maxWidth={400} textAlign="center">
            <Trans>Follow the instructions on the card machine </Trans>
          </Text>
          {isPaymentFailed && (
            <Stack
              alignItems="center"
              justifyContent="center"
              border={'1px solid'}
              borderColor="red.error.300"
              background="red.error.25"
              padding={4}
              borderRadius={12}
              direction="row"
              gap={2}
            >
              <Icon as={MdErrorOutline} color="red.error.600" width={8} height={8} />
              <Stack>
                <Text color="red.error.700" fontSize={20} fontWeight={500}>
                  <Trans>Payment failed</Trans>
                </Text>
                <Text color={'red.error.700'} fontSize={18} fontWeight={300}>
                  <Trans>Your card wasn&apos;t charged. Please try again.</Trans>
                </Text>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Button
          onClick={onCancelOrderClick}
          sx={{
            background: 'white',
            color: 'gray.700',
            border: '1px solid',
            borderColor: 'gray.300',
            fontSize: 18,
            height: '60px',
          }}
          leftIcon={<CloseIcon color="gray.700" width="10px" mr={3} />}
        >
          <Text>
            <Trans>Cancel order</Trans>
          </Text>
        </Button>
      </Stack>
    </Stack>
  );
};

export const PaymentInstructionsPage = () => {
  const navigate = useNavigate();
  const { order, setOrder } = useOrder();
  const { customerName, orderUuid } = useParams();
  const cartRequest = useCartRequest();
  const { cart } = useCart();
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');
  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');

  const { isKioskUser } = useOnSiteInfo();

  const prepareOrderParams = useMemo(() => {
    const { items, restaurant_platform_id: restaurantPlatformId } = cartRequest;

    if (!orderUuid || !customerName) navigate(-1);

    return {
      items,
      restaurantPlatformId,
      orderUuid,
      terminalUuid,
      customerName,
    };
  }, [cartRequest, customerName, orderUuid, terminalUuid, navigate]);

  const { data: orderPayment } = useOrderQuery({
    orderUuid,
    options: {
      refetchInterval: 4000,
      enabled: !!prepareOrderParams,
    },
  });

  const isPaymentFailed = useMemo(() => {
    return orderPayment?.status === 'payment_failed';
  }, [orderPayment?.status]);

  useEffect(() => {
    if (
      orderPayment &&
      ['created', 'payment_succeeded', 'ready_for_dispatch', 'delivered', 'accepted'].includes(orderPayment.status)
    ) {
      dataLayer.logPaymentSucceeded(cart.kitchenLabel, cart.conceptLabel, orderPayment, '', 'onsite');
      setOrder(orderPayment);
      navigate(`/onSite/orderConfirmed/${orderPayment.uuid}/${orderPayment.number}`);
    }
  }, [orderPayment, navigate, setOrder, cart.kitchenLabel, cart.conceptLabel, order]);

  const {
    isLoading: isPaymentBeingCanceled,
    mutate: deletePayment,
    mutateAsync: deleteAsyncPayment,
  } = useDeleteOrderPaymentMutation();

  const { resetCart } = useCart();

  const { endOnSiteSession } = useOnSiteSession();

  const cancelPayment = () => {
    deletePayment({
      locationUuid,
      terminalUuid,
    });

    navigate(`/onSite/customerName`, { state: { backButtonUrl: `/onSite/menu/${order?.restaurant_platform_id}` } });
  };

  const cancelOrder = async () => {
    await deleteAsyncPayment({
      locationUuid,
      terminalUuid,
    });

    resetCart();
    navigate(`/onSite/${locationUuid}`);
  };

  return (
    <PairDeviceLayout headerTitle={t`Checkout`}>
      {isPaymentBeingCanceled ? (
        <Flex>
          <Spinner size="xl" mx="auto" />
        </Flex>
      ) : (
        <PaymentIndications
          onCancelOrderClick={() => {
            if (isKioskUser) {
              endOnSiteSession({
                sessionEndReason: 'abandon',
              });
            }

            cancelOrder();
          }}
          onCancelPaymentClick={cancelPayment}
          isPaymentFailed={isPaymentFailed}
        />
      )}
    </PairDeviceLayout>
  );
};
