import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';

import footerLogo from '@/assets/img/taster_footer.png';
import Header from '@/layouts/Header';

type Props = {
  title: string;
  hasArrowBack?: boolean;
  url?: string;
  hasPoweredBy?: boolean;
  hasSettingsButton?: boolean;
  maxWidthBreakpoints?: Record<string, string>;
  children: React.ReactNode;
  headerExtraContent?: React.ReactNode;
};

const AppLayout = ({
  title,
  hasArrowBack = false,
  url,
  hasPoweredBy = false,
  hasSettingsButton = false,
  maxWidthBreakpoints,
  children,
  headerExtraContent,
}: Props) => {
  return (
    <>
      <Header title={title} hasArrowBack={hasArrowBack} url={url} hasSettingsButton={hasSettingsButton}>
        {headerExtraContent}
      </Header>
      <Box
        maxW={{ base: '100%', md: '640px', ...maxWidthBreakpoints }}
        margin="auto"
        backgroundColor="#FBFCFE"
        minH="calc(100vh - 4.5rem)"
        position="relative"
      >
        {children}
        {hasPoweredBy && (
          <Flex
            position="absolute"
            bottom={6}
            color="gray.disabled"
            fontWeight={500}
            justifyContent="center"
            alignItems="center"
            left={0}
            right={0}
          >
            <Image src={footerLogo} alt="Taster" width={250} />
          </Flex>
        )}
      </Box>
    </>
  );
};

export default AppLayout;
