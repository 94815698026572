import { useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useCallback } from 'react';
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

import { ConceptInformation, useConceptsConfigurationQuery } from '@/api/gateway-click-collect/concepts';
import { Restaurant, useRestaurantQueryOptions, useRestaurantsQuery } from '@/api/gateway-click-collect/restaurants';
import { HOUR_MS, MINUTE_MS } from '@/constants';
import { useBackgroundQueries } from '@/contexts/KioskDataProvider/useBackgroundQueries';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';
import { usePollingTerminalStatus } from '@/hooks/usePollingTerminalStatus';

export interface KioskDataContext {
  conceptsInformation?: ConceptInformation[];
  isError: boolean;
  isLoading: boolean;
  restaurants?: Restaurant[];
  seedRestaurantCache: (restaurantPlatformId: string) => void;
}

export const useKioskDataContext = () => useOutletContext<KioskDataContext>();

export const KioskDataProvider = () => {
  const { locationUuid } = useParams() as { locationUuid: string };
  const queryClient = useQueryClient();
  const { hasPairedTerminal } = useOnSiteInfo();
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');

  usePollingTerminalStatus({
    locationUuid,
    terminalUuid,
    maxTry: 5,
  });

  const {
    data: conceptConfigs,
    isLoading: isLoadingConceptConfigs,
    isError: isConceptConfigsError,
  } = useConceptsConfigurationQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: HOUR_MS * 3,
    },
  });

  const {
    data: restaurants,
    isLoading: isLoadingRestaurants,
    isError: isRestaurantsError,
  } = useRestaurantsQuery({
    options: {
      cacheTime: Infinity,
      refetchInterval: MINUTE_MS * 5,
    },
    requestParams: {
      locationUuid,
      withOpeningInformation: true,
    },
  });

  useBackgroundQueries({ restaurants });

  const isLoading = isLoadingConceptConfigs || isLoadingRestaurants;
  const isError = isConceptConfigsError || isRestaurantsError;

  const seedRestaurantCache = useCallback(
    (restaurantPlatformId: string) => {
      const restaurant = restaurants?.find((restaurant) => restaurant.restaurantPlatformId === restaurantPlatformId);

      if (restaurant) {
        queryClient.setQueryData(
          useRestaurantQueryOptions.queryKey({ restaurantPlatformId, withOpeningInformation: true }),
          () => restaurant
        );
      }
    },
    [queryClient, restaurants]
  );

  if (!hasPairedTerminal) {
    return <Navigate to="/onSite/terminalDisconnected" />;
  }

  return (
    <Outlet
      context={
        {
          conceptsInformation: conceptConfigs?.concepts,
          isError,
          isLoading,
          restaurants,
          seedRestaurantCache,
        } satisfies KioskDataContext
      }
    />
  );
};
