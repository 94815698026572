import { Box, Image, List, ListItem, Stack, useBoolean, useDisclosure } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Dayjs } from 'dayjs';
import { keyBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { Category, Menu, MenuElement } from '@/api/types';
import BluredOverlay from '@/components/BluredOverlay';
import CartDrawer from '@/components/CartDrawer';
import ClosingMessage from '@/components/ClosingMessage';
import { InactiveUserModal } from '@/components/InactiveUserModal/InactiveUserModal';
import ItemDrawer from '@/components/ItemDrawer';
import MenuCategory from '@/components/MenuCategory';
import PageLoader from '@/components/PageLoader';
import { RestaurantInfo } from '@/components/RestaurantInfo';
import WebviewMessage from '@/components/WebviewMessage';
import WebviewModal from '@/components/WebviewModal';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import { getWebViewType } from '@/helpers/webview';
import useMenu from '@/hooks/useMenu';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { usePollingTerminalStatus } from '@/hooks/usePollingTerminalStatus';
import usePreOrder from '@/hooks/usePreOrder';
import { useUpdateMetadata } from '@/hooks/useUpdateMetadata';
import AppLayout from '@/layouts/AppLayout';
import type { ItemCart } from '@/types';

const MenuPage = () => {
  const { restaurantPlatformId } = useParams();
  const { isOpen: isCartOpen, onOpen: openCart, onClose: closeCart } = useDisclosure();

  const { menu, isError, isLoading, shifts, isOpenToday, opensAt, openingLabel } = useMenu(restaurantPlatformId);
  useUpdateMetadata({ title: menu?.restaurantName, description: menu?.description });

  const { isPreOrderAvailable } = usePreOrder({ shifts });
  const { hasPairedTerminal, navigateAuthenticated } = useOnSiteInfo();
  const { isOpen: isWebviewModalOpen, onOpen: onWebviewModalOpen, onClose: onWebviewModalClose } = useDisclosure();
  const [isWebviewModalDone, setIsWebviewModalDone] = useBoolean(false);

  const { isOpen: isAddItemToCartOpen, onOpen: onOpenAddItem, onClose: onCloseAddItem } = useDisclosure();
  const [selectedMenuElement, setSelectedMenuElement] = useState<MenuElement | null>(null);

  const [locationUuid] = useLocalStorage('LOCATION_UUID', null);
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', null);
  const [, setCurrencyCode] = useLocalStorage('CURRENCY_CODE', '');

  useEffect(() => {
    if (menu?.currencyCode) setCurrencyCode(menu.currencyCode);
  }, [menu?.currencyCode, setCurrencyCode]);

  usePollingTerminalStatus({
    locationUuid,
    terminalUuid,
    maxTry: 5,
  });

  const { cart, setCart, resetCart } = useCart();

  useEffect(() => {
    if (cart.restaurantPlatformId && cart.restaurantPlatformId !== restaurantPlatformId) {
      resetCart();
    }
  }, [cart, resetCart, restaurantPlatformId]);

  usePageViewTracker(menu?.kitchenLabel, menu?.conceptLabel);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!restaurantPlatformId || isError || !menu) return navigateAuthenticated('notFound');

  const onItemClick = (menuElement: MenuElement) => {
    setSelectedMenuElement(menuElement);
    onOpenAddItem();
  };

  const isCartDrawerDisplayed = isPreOrderAvailable || menu.isOpen;

  const onUpdateCart = (item: ItemCart) => {
    if (!item || !menu) return;

    if (
      !isWebviewModalDone &&
      getWebViewType() === 'webview_ios' &&
      [
        '23240662-d912-49bc-b6d8-5881b748e879',
        'f8de7c37-10d4-4994-8d16-d8816c4345f3',
        '50c62c6e-601a-4e1b-8421-ef0fe4718842',
        'f870123a-c7e9-47c8-bdf9-e2e5624280cf',
        'e7a713d2-4f07-4827-8d0d-4b22725eb975',
        '3911137c-e103-4125-9c35-7d11b3a8cc38',
      ].includes(menu.locationUuid)
    ) {
      onWebviewModalOpen();
      setIsWebviewModalDone.on();
    }

    const items = cart.restaurantPlatformId === restaurantPlatformId ? [...cart.items, item] : [item];

    const { restaurantAddress, restaurantName, accessInstructions, kitchenLabel, conceptLabel } = menu;

    dataLayer.logItemAdded(kitchenLabel, conceptLabel, item, hasPairedTerminal ? 'onsite' : 'online');

    setCart({
      restaurantPlatformId,
      items,
      restaurantAddress,
      restaurantName,
      accessInstructions,
      kitchenLabel,
      conceptLabel,
    });

    onCloseAddItem();
  };

  return (
    <AppLayout
      title={menu.label}
      hasArrowBack
      url={hasPairedTerminal ? `/onSite/${menu.locationUuid}` : `/${menu.locationUuid}`}
      hasPoweredBy={false}
      headerExtraContent={<WebviewMessage />}
    >
      <Box pos="relative">
        <MenuHero imageUrl={menu.imageUrl} isOpen={menu.isOpen} isOpenToday={isOpenToday} opensAt={opensAt} />
      </Box>
      <Stack p={6} pb={10} mx="auto" gap={6}>
        <RestaurantInfo
          locationUuid={menu.locationUuid}
          conceptUuid={menu.conceptUuid}
          kitchenLabel={menu.kitchenLabel}
          restaurantName={menu.restaurantName}
          description={menu.description}
          kitchenAddress={menu.restaurantAddress}
          openingHours={menu.openingHours}
          openingLabel={openingLabel}
          isRestaurantOpen={menu.isOpen}
          sx={{ justifyContent: 'stretch' }}
        />
        <MenuList menu={menu} onItemClick={onItemClick} />
      </Stack>
      <ItemDrawer
        currencyCode={menu.currencyCode}
        menuElement={selectedMenuElement}
        isSubmitDisabled={!isOpenToday}
        disabledReason={t`This restaurant is currently closed.`}
        onAddToCart={onUpdateCart}
        isOpen={isAddItemToCartOpen}
        onClose={onCloseAddItem}
      />
      {isCartDrawerDisplayed && (
        <CartDrawer
          cartItems={cart.items}
          currencyCode={menu.currencyCode}
          isDrawerOpen={isCartOpen}
          isRestaurantOpen={menu.isOpen}
          onClose={closeCart}
          onOpen={openCart}
          shifts={shifts}
        />
      )}
      <WebviewModal isOpen={isWebviewModalOpen} onClose={onWebviewModalClose} />
      {hasPairedTerminal && <InactiveUserModal locationUuid={menu.locationUuid} />}
    </AppLayout>
  );
};

const _MenuHero = ({
  imageUrl,
  isOpen,
  isOpenToday,
  opensAt,
}: {
  imageUrl: string;
  isOpen: boolean;
  isOpenToday: boolean;
  opensAt?: Dayjs;
}) => {
  return (
    <>
      <Image src={imageUrl} />
      {!isOpen && (
        <BluredOverlay>
          <ClosingMessage isOpenToday={isOpenToday} opensAt={opensAt} />
        </BluredOverlay>
      )}
    </>
  );
};

const MenuHero = React.memo(_MenuHero);

const _MenuList = ({ menu, onItemClick }: { menu: Menu; onItemClick: (item: MenuElement) => void }) => {
  const menuElementMap = keyBy(menu?.menuElements, 'menuElementUuid');

  const getCategoryMenuElements = (category: Category): MenuElement[] =>
    category.menuElementUuids.reduce<MenuElement[]>((acc, menuElementUuid) => {
      const menuElement = menuElementMap[menuElementUuid];

      if (menuElement) {
        acc.push(menuElement);
      }

      return acc;
    }, []);

  return (
    <List>
      {menu?.categories.map((category: Category) => (
        <ListItem mb={6} key={category.uuid}>
          <MenuCategory
            category={category}
            onItemClick={onItemClick}
            menuElements={getCategoryMenuElements(category)}
            currencyCode={menu.currencyCode}
          />
        </ListItem>
      ))}
    </List>
  );
};

const MenuList = React.memo(_MenuList);

export default MenuPage;
