import { Flex, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { locationType, Marker, StaticGoogleMap } from 'react-static-google-map';

import { getCoordinates } from '@/api/api';
import ApiError from '@/api/ApiError';

const MAPS_API_KEY = import.meta.env.VITE_MAPS_API_KEY;

interface Props {
  kitchenAddress: string;
}

const OrderPickupMap = ({ kitchenAddress }: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapWidth, setMapWidth] = useState(0);

  const {
    data: coordinates,
    isLoading,
    error,
  } = useQuery<locationType, ApiError>(['getCoordinates', kitchenAddress], () => getCoordinates(kitchenAddress));

  useEffect(() => {
    setMapWidth(mapRef.current?.clientWidth ?? 0);
  });

  const handleClick = () => {
    if (coordinates && typeof coordinates === 'object' && 'lat' in coordinates) {
      open(`https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lng}`);
    }
  };

  if (error) return <></>;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      gap={6}
      direction="column"
      ref={mapRef}
      _hover={coordinates ? { cursor: 'pointer' } : {}}
    >
      {isLoading && <Spinner size="xl" my={8} />}
      {coordinates && !isLoading && (
        <StaticGoogleMap
          size={`${mapWidth}x250`}
          apiKey={MAPS_API_KEY}
          maptype="terrain"
          zoom="16"
          onClick={() => handleClick()}
        >
          <Marker location={coordinates} iconURL={`${location.origin}/gmaps_marker_icon.png`} />
        </StaticGoogleMap>
      )}
    </Flex>
  );
};

export default OrderPickupMap;
