import { t } from '@lingui/macro';
import { setTag } from '@sentry/react';
import { useQueries } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useCallback, useEffect, useMemo } from 'react';

import {
  ModelConfiguration,
  OpeningHours,
  restaurantsKeys,
  useLocationRestaurantsQuery,
} from '@/api/gateway-click-collect';
import { useTasterApis } from '@/api/TasterApiContext';
import { useGetKitchenInfosQuery } from '@/api/useGetKitchenInfos';
import { getResponsivePictureClassification } from '@/helpers/onSite/formatOnsiteMenuPictures';
import { formatOnsiteOpeningHours } from '@/helpers/onSite/formatOnsiteOpeningHours';

import { useOnSiteInfo } from '../useOnSiteInfo';

const FIVE_MINUTES_REFETCH_INTERVAL = 300000;
const ONE_HOURS_AND_HALF_INTERVAL = 5400000;

export const useRestaurantsList = ({
  locationUuid,
  hasPairedTerminal,
  conceptConfigs,
}: {
  conceptConfigs: ModelConfiguration;
  hasPairedTerminal: boolean;
  locationUuid: string;
}) => {
  const { isKioskUser } = useOnSiteInfo();

  const [kitchenLabel] = useLocalStorage<string>('KITCHEN_LABEL', t`Unknown`);

  const {
    data: kitchenInfos,
    isLoading: areCustomerOrderRestaurantsLoading,
    isError: areCustomerOrderRestaurantsOnError,
    isSuccess: areCustomerOrderOnSuccess,
  } = useGetKitchenInfosQuery({
    locationUuid,
    options: {
      enabled: !!locationUuid && !isKioskUser,
      refetchInterval: hasPairedTerminal ? ONE_HOURS_AND_HALF_INTERVAL : false,
    },
  });

  const {
    data: gatewayRestaurants,
    isLoading: areGatewayRestaurantsLoading,
    error: areGatewayRestaurantsOnError,
    isSuccess: areGatewayRestaurantsOnSuccess,
  } = useLocationRestaurantsQuery({
    requestParams: {
      locationUuid,
      withOpeningInformation: true,
    },
    options: {
      enabled: !!locationUuid && isKioskUser,
      refetchInterval: ONE_HOURS_AND_HALF_INTERVAL,
    },
  });

  useEffect(() => {
    if (areGatewayRestaurantsOnSuccess || areCustomerOrderOnSuccess) setTag('location_uuid', locationUuid);
  }, [areGatewayRestaurantsOnSuccess, areCustomerOrderOnSuccess, locationUuid]);

  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  const restaurantPlatformIds = useMemo(
    () => gatewayRestaurants?.map(({ restaurantPlatformId }) => restaurantPlatformId) ?? [],
    [gatewayRestaurants]
  );

  const statusesList = useQueries({
    queries: restaurantPlatformIds.map((restaurantPlatformId) => ({
      queryKey: restaurantsKeys.getOpenStatus({ restaurantPlatformId }),
      queryFn: async () => ({
        restaurantPlatformId,
        statuses: await restaurantsApi.getRestaurantStatus({ restaurantPlatformId }),
      }),
      enabled: isKioskUser && !!restaurantPlatformIds?.length,
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
    })),
  });
  const statuses = useMemo(() => statusesList.map(({ data }) => data), [statusesList]);

  const getIsRestaurantOpen = useCallback(
    ({ restaurantPlatformId, openingHours }: { restaurantPlatformId: string; openingHours?: OpeningHours }) => {
      const searchedRestaurantStatus = statuses.find(
        (restaurantStatus) => restaurantStatus?.restaurantPlatformId === restaurantPlatformId
      )?.statuses.status;

      if (!openingHours || !searchedRestaurantStatus) return false;

      return formatOnsiteOpeningHours({
        restaurantOpeningHours: openingHours,
        status: searchedRestaurantStatus,
      })?.isOpenNow;
    },
    [statuses]
  );

  const restaurantsList = useMemo(() => {
    if (!isKioskUser) return kitchenInfos?.menuInfos ?? [];

    return (
      gatewayRestaurants
        ?.map(
          ({
            kitchenInstructions,
            restaurantPlatformId,
            conceptUuid,
            currencyCode,
            restaurantLabel,
            openingHours = { weekdays: {} },
            status = 'close',
          }) => {
            const { opensAt, shifts } = formatOnsiteOpeningHours({
              restaurantOpeningHours: openingHours,
              status,
            });

            const conceptConfig = conceptConfigs.concepts.find(({ uuid }) => uuid === conceptUuid);

            return {
              kitchenInstructions,
              restaurantPlatformId,
              conceptUuid,
              conceptLogo: conceptConfig?.logoPictures[0].url ?? t`Unknown brand`,
              currencyCode,
              kitchenLabel: kitchenInfos?.label ?? t`Unknown`,
              label: restaurantLabel,
              menuPicture:
                getResponsivePictureClassification({
                  windowWidth: window.innerWidth,
                  pictureClassifications: conceptConfig?.heroPictures,
                })?.url ?? t`Unknown`,
              openingHours,
              isOpen: getIsRestaurantOpen({ restaurantPlatformId, openingHours }),
              opensAt: opensAt ?? undefined,
              shifts,
            };
          }
        )
        .filter((restaurant) => Boolean(restaurant)) ?? []
    );
  }, [
    isKioskUser,
    gatewayRestaurants,
    kitchenInfos?.menuInfos,
    getIsRestaurantOpen,
    conceptConfigs.concepts,
    kitchenInfos?.label,
  ]);

  const areRestaurantsLoading =
    (isKioskUser && areGatewayRestaurantsLoading) || (!isKioskUser && areCustomerOrderRestaurantsLoading);

  const areRestaurantsOnError = areCustomerOrderRestaurantsOnError || areGatewayRestaurantsOnError;

  return {
    areRestaurantsOnError,
    areRestaurantsLoading,
    restaurantsList,
    kitchenLabel: kitchenInfos?.label ?? kitchenLabel,
  };
};
