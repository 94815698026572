import { t } from '@lingui/macro';
import dayjs from 'dayjs';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import dataLayer from '@/helpers/dataLayer.helpers';

type OnSiteSessionContextValue = {
  sessionId?: string;
  startOnSiteSession: ({ kitchenLabel, terminalUuid }: { kitchenLabel: string; terminalUuid: string }) => void;
  endOnSiteSession: ({ sessionEndReason }: { sessionEndReason: 'abandon' | 'payed' | 'timeout' }) => void;
};

const OnSiteSessionContext = createContext<OnSiteSessionContextValue | null>(null);

const useOnSiteSession = () => {
  const context = useContext(OnSiteSessionContext);

  if (!context) throw new Error('Make sure you are using useOnSiteSession within a OnSiteSessionProvider');

  return context;
};

type OnSiteSessionProviderProps = { children: ReactNode };

const OnSiteSessionProvider = ({ children }: OnSiteSessionProviderProps) => {
  const [sessionId, setOnSiteSessionId] = useState<string>();

  const startOnSiteSession = useCallback(
    ({ kitchenLabel, terminalUuid }: { kitchenLabel?: string; terminalUuid: string }) => {
      const timeStamp = dayjs().format('HH:mm:ss-DD-MM-YYYY');

      const newSessionId = `${kitchenLabel ?? t`Unknown`}-${terminalUuid}-${timeStamp}`;

      setOnSiteSessionId(newSessionId);

      dataLayer.logOnSiteSessionStart({ sessionId: newSessionId });
    },
    []
  );

  const endOnSiteSession = useCallback(
    ({ sessionEndReason }: { sessionEndReason: 'abandon' | 'payed' | 'timeout' }) => {
      if (sessionId) {
        dataLayer.logOnSiteSessionEnd({
          sessionEndReason,
          sessionId,
        });

        setOnSiteSessionId(undefined);
      }
    },
    [sessionId]
  );

  const value = useMemo(
    () => ({
      sessionId,
      startOnSiteSession,
      endOnSiteSession,
    }),
    [sessionId, endOnSiteSession, startOnSiteSession]
  );

  return <OnSiteSessionContext.Provider value={value}>{children}</OnSiteSessionContext.Provider>;
};

export { OnSiteSessionProvider, useOnSiteSession };
