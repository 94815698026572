/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import type { Order } from '@/api/types';
import { useOrderQuery } from '@/api/useOrderQuery';
import { useSendCustomerEmailMutation } from '@/api/useSendCustomerEmailMutation';
import IntercomLauncher from '@/components/IntercomLauncher';
import OrderDetailsCard from '@/components/Order/OrderDetailsCard';
import OrderInfosCard from '@/components/Order/OrderInfosCard';
import OrderPickupMap from '@/components/Order/OrderPickupMap';
import OrderReview from '@/components/Order/OrderReview';
import OrderStatusCard from '@/components/Order/OrderStatusCard';
import { SubmitReceiptEventHandler } from '@/components/Order/Receipt/ReceiptForm';
import ReceiptModal from '@/components/Order/Receipt/ReceiptModal';
import SendReceiptStatus from '@/components/Order/Receipt/ReceiptStatus';
import PageLoader from '@/components/PageLoader';
import PongoWheel from '@/components/PongoWheel';
import { useOrder } from '@/contexts/order';
import dataLayer from '@/helpers/dataLayer.helpers';
import pongoUrls from '@/helpers/pongoUrls.helpers';
import useMenu from '@/hooks/useMenu';
import useOrderItems from '@/hooks/useOrderItems';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import AppLayout from '@/layouts/AppLayout';
import { OrderStatus } from '@/types';

const OrderStatusPage = () => {
  const { orderUuid } = useParams();

  const { order, orderStatus, setOrder } = useOrder();

  const {
    data: fetchedOrder,
    error,
    isLoading,
  } = useOrderQuery({
    orderUuid,
    options: {
      refetchInterval: 3000,
      enabled: !!orderUuid,
    },
  });

  if (fetchedOrder && fetchedOrder !== order) {
    setOrder(fetchedOrder);
  }

  if (error || !orderUuid) {
    return <Navigate to={`/notFound`} />;
  }

  if (isLoading || !order) {
    return <PageLoader />;
  }

  return <OrderStatusContainer order={order} orderStatus={orderStatus} />;
};

const OrderStatusContainer = ({ order, orderStatus }: { order: Order; orderStatus?: OrderStatus }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [paymentUuid] = useLocalStorage('PAYMENT_UUID', '');

  const { menu } = useMenu(order.restaurant_platform_id);
  const { orderItems } = useOrderItems(order, menu);

  const [userEmail, setUserEmail] = useState<string>('');

  const {
    isError,
    isSuccess,
    mutate: submitReceiptForm,
    isLoading,
  } = useSendCustomerEmailMutation({
    onSuccess: (_, variables) => {
      setUserEmail(variables.customerEmail);
    },
    onSettled: () => {
      onClose();
    },
  });

  const handleSubmit: SubmitReceiptEventHandler = (email, isOptedIn, resetForm) => {
    submitReceiptForm(
      {
        customerEmail: email,
        marketingConsent: isOptedIn,
        orderUuid: order.uuid,
        paymentUuid,
      },
      { onSuccess: () => resetForm() }
    );
  };

  usePageViewTracker(menu?.kitchenLabel, menu?.conceptLabel);

  return (
    <AppLayout title={t`Your order`} hasPoweredBy>
      <Flex flexDirection="column" p="24px" gap="24px" pb="6rem" marginX="auto">
        {(isSuccess || isError) && <SendReceiptStatus email={userEmail} isReceiptSentOnSuccess={isSuccess} />}
        {pongoUrls[order.restaurant_platform_id] && (
          <PongoWheel
            pongoUrl={pongoUrls[order.restaurant_platform_id]}
            onClick={() => menu && dataLayer.logPongoClicked(menu.kitchenLabel, menu.conceptLabel)}
          />
        )}
        <OrderStatusCard number={order.number} status={orderStatus} readyAt={order.ready_at} />
        <IntercomLauncher />
        {menu && (
          <OrderInfosCard
            kitchenName={menu.restaurantName}
            kitchenAddress={menu.restaurantAddress}
            kitchenAccessInstructions={menu.accessInstructions}
            pickupTime={order.ready_at}
            orderPickupMap={<OrderPickupMap kitchenAddress={menu.restaurantAddress} />}
          />
        )}
        <Button size="lg" w="100%" variant="secondary" onClick={onOpen}>
          <Trans>Get receipt</Trans>
        </Button>
        {orderItems && <OrderDetailsCard items={orderItems} />}
        {menu?.urlGoogleReviews && (
          <OrderReview
            googleReviewUrl={menu.urlGoogleReviews}
            onReview={(review) => menu && dataLayer.logOrderReviewed(review, menu.kitchenLabel, menu.conceptLabel)}
            onGoogleReviewOpen={() => menu && dataLayer.logGoogleReviewOpened(menu.kitchenLabel, menu.conceptLabel)}
            onIntercomOpen={() => menu && dataLayer.logIntercomOpened(menu.kitchenLabel, menu.conceptLabel)}
            onExtraStepClose={() => menu && dataLayer.logReviewExtraStepClosed(menu.kitchenLabel, menu.conceptLabel)}
          />
        )}
      </Flex>
      <ReceiptModal
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={isLoading}
        isReceiptSent={isSuccess}
      />
    </AppLayout>
  );
};

export default OrderStatusPage;
