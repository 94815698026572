import { Box, DrawerHeader, Flex, Image, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import React from 'react';

import { MenuElement } from '@/api/types';
import { hasMenuItemCalories } from '@/helpers/menu.helpers';

interface Props {
  menuElement: MenuElement;
}

const ItemDrawerHeader = ({ menuElement }: Props) => {
  const {
    description,
    imageUrl,
    label,
    nutritionalInfo: { energyKcal },
  } = menuElement;

  return (
    <DrawerHeader padding={0}>
      <Flex direction="column" gap="0.5">
        {imageUrl && (
          <Image
            alt={label}
            objectFit="cover"
            src={imageUrl.replace('132x99', '768x432')}
            fallbackSrc={imageUrl}
            width="100%"
            height="180px"
          />
        )}
        <Box py={4} px={6}>
          <Text as="h2" mb={1} size="2xl" fontWeight="600">
            {label}
          </Text>
          <Text color="gray.500" fontSize="14px" fontWeight="400">
            {description}
            {hasMenuItemCalories(menuElement) && (
              <Text as="span" ml={2}>
                {energyKcal}
                <Trans>kcal</Trans>
              </Text>
            )}
          </Text>
        </Box>
      </Flex>
    </DrawerHeader>
  );
};

export default React.memo(ItemDrawerHeader);
