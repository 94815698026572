import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import { Shift } from '@/api/types';

export const getPreOrderTimes = (shifts: Shift[]): Dayjs[] => {
  const timeframeInMins: [number, ManipulateType] = [15, 'minutes'];

  const nextTime = (time: Dayjs, isBackward: boolean) =>
    isBackward
      ? dayjs.prototype.subtract.apply(time, timeframeInMins)
      : dayjs.prototype.add.apply(time, timeframeInMins);

  const isOverTime = (time: Dayjs, maxTime: Dayjs, isBackward: boolean) =>
    isBackward ? time.isBefore(maxTime) : time.isAfter(maxTime);

  const getShiftPreOrderTimes = (shift: Shift): Dayjs[] => {
    const preOrderTimes = [];

    const isBackward = shift.shiftStart === undefined ? true : false;

    const startTime = dayjs(shift.shiftStart);
    const endTime = dayjs(shift.shiftEnd);

    let preOrderTime = isBackward ? endTime : startTime;
    const maxTime = isBackward ? nextTime(startTime, false) : endTime;

    preOrderTime = nextTime(preOrderTime, isBackward);
    while (!isOverTime(preOrderTime, maxTime, isBackward)) {
      preOrderTimes.push(preOrderTime);
      preOrderTime = nextTime(preOrderTime, isBackward);
    }

    if (isBackward && nextTime(dayjs(), false) <= dayjs(shift.shiftEnd)) preOrderTimes.push(dayjs(shift.shiftEnd));

    return preOrderTimes;
  };

  return shifts.map(getShiftPreOrderTimes).flat().sort();
};

export interface UsePreOrderProps {
  refreshInterval?: number;
  shifts: Shift[];
}

const usePreOrder = ({ shifts, refreshInterval }: UsePreOrderProps) => {
  const [preOrderTimes, setPreOrderTimes] = useState<Dayjs[]>([]);

  useEffect(() => {
    setPreOrderTimes(getPreOrderTimes(shifts));

    if (refreshInterval) {
      const updatePreOrderTimes = setInterval(() => setPreOrderTimes(getPreOrderTimes(shifts)), refreshInterval);

      return () => clearInterval(updatePreOrderTimes);
    }
  }, [JSON.stringify(shifts)]);

  const isPreOrderAvailable = useMemo(() => preOrderTimes.length > 0, [preOrderTimes]);

  return { isPreOrderAvailable, preOrderTimes };
};

export default usePreOrder;
