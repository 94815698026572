import { Box, Button, DrawerFooter, Spinner, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { ForwardedRef, forwardRef } from 'react';

import useFormatPrice from '@/hooks/useFormatPrice';

type Props = {
  buttonLabel: string;
  currencyCode: string;
  isError?: boolean;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  onClose: () => unknown;
  onErrorClick: () => void;
  onSubmit: () => unknown;
  price?: number;
};

const MIN_CART_AMOUNT = 0.5;

const CartDrawerFooter = forwardRef(
  (
    { buttonLabel, currencyCode, isError, isLoading, isSubmitDisabled, onClose, onErrorClick, onSubmit, price }: Props,
    ref?: ForwardedRef<HTMLButtonElement>
  ) => {
    const { formatPrice } = useFormatPrice();

    if (isError) {
      return (
        <DrawerFooter>
          <Stack>
            <Text>
              <Trans>Sorry, we encountered a problem. To checkout, please empty your cart and start again.</Trans>
            </Text>
            <Button onClick={onErrorClick}>
              <Trans>Empty cart</Trans>
            </Button>
          </Stack>
        </DrawerFooter>
      );
    }

    return (
      <DrawerFooter borderTopWidth="1px" pb={6}>
        <Box w="100%">
          <Box
            marginBottom={4}
            fontSize="lg"
            fontWeight="500"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text as="span">
              <Trans>Total</Trans>
            </Text>
            {isLoading || !price ? <Spinner size="xs" /> : <Text>{formatPrice(price, currencyCode)}</Text>}
          </Box>
          {price &&
            (price >= MIN_CART_AMOUNT ? (
              <Button
                ref={ref}
                w="100%"
                size="lg"
                isLoading={isLoading}
                isDisabled={isSubmitDisabled}
                onClick={onSubmit}
              >
                {buttonLabel}
              </Button>
            ) : (
              <Box>
                <Text color="red.error" fontWeight={500}>
                  <Trans>Adjust cart to place order</Trans>
                </Text>
                <Text color="gray.disabled">
                  <Trans
                    values={{
                      delta: formatPrice(MIN_CART_AMOUNT - price, currencyCode),
                      minAmount: formatPrice(MIN_CART_AMOUNT, currencyCode),
                    }}
                    id="You are {delta} away from the store minimum"
                  />
                </Text>
                <Button ref={ref} w="100%" size="lg" onClick={onClose} mt={4}>
                  <Trans>Add items</Trans>
                </Button>
              </Box>
            ))}
        </Box>
      </DrawerFooter>
    );
  }
);

CartDrawerFooter.displayName = 'CartDrawerFooter';

export default CartDrawerFooter;
