import { Flex, Image, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';

import type { MenuElement } from '@/api/types';
import itemPlaceholderImgSrc from '@/assets/img/item_placeholder.png';
import { hasMenuItemCalories } from '@/helpers/menu.helpers';
import useFormatPrice from '@/hooks/useFormatPrice';

interface Props {
  menuItem: MenuElement;
  currencyCode: string;
}

const itemDescriptionStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
};

const MenuItem = ({ menuItem, currencyCode }: Props) => {
  const { formatPrice } = useFormatPrice();

  return (
    <Flex gap={2} align="center">
      <Image
        src={menuItem.imageUrl ?? itemPlaceholderImgSrc}
        alt={menuItem.label}
        boxSize="64px"
        borderRadius="md"
        opacity={menuItem.available ? '1' : '0.4'}
        alignSelf="baseline"
        objectFit="cover"
        loading="lazy"
      />
      <Flex direction="column" justify="space-between" align="flex-start">
        <Text fontWeight="500" fontSize="md" color={menuItem.available ? '#02091D' : '#74767B'} textAlign="start">
          {menuItem.label}
        </Text>
        <Text fontWeight="400" fontSize="xs" color="#74767b" sx={itemDescriptionStyle}>
          {menuItem.available ? menuItem.description : t`Item unavailable`}
        </Text>
        {hasMenuItemCalories(menuItem) && (
          <Text color="gray.500" fontSize="xs" mt={2}>
            {menuItem.nutritionalInfo.energyKcal}
            <Trans>kcal</Trans>
          </Text>
        )}
      </Flex>
      {menuItem.available && (
        <Text ml="auto" fontWeight="500" fontSize="sm" wordBreak="keep-all" whiteSpace="nowrap">
          {formatPrice(menuItem.sellingPrice, currencyCode)}
        </Text>
      )}
    </Flex>
  );
};

export default MenuItem;
