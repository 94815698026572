import { Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';

type Props = {
  isOpenToday: boolean;
  opensAt?: Dayjs;
};

const ClosingMessage = ({ isOpenToday, opensAt }: Props) => {
  const opensAtMessage = useMemo(() => {
    if (opensAt) {
      return t({ id: `Opens at {hour}`, values: { hour: opensAt.format('LT') } });
    }

    return t`Closed`;
  }, [opensAt]);

  return (
    <>
      <Text fontSize="2xl" fontWeight={500}>
        {opensAtMessage}
      </Text>
      {isOpenToday && <Trans>Available for pre-order</Trans>}
    </>
  );
};

export default ClosingMessage;
