import { Heading, Stack } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDeleteOrderPaymentMutation } from '@/api/useDeleteOrderPaymentMutation';
import { usePrepareOrderOnSiteMutation } from '@/api/usePrepareOrderOnSiteMutation';
import { useTerminalStatusMutation } from '@/api/useTerminalStatusMutation';
import cycleAnimation from '@/assets/anim/lottie_cycle.json';
import { BackButton } from '@/components/BackButton';
import { CustomerNameForm } from '@/components/CustomerNameForm';
import { LottieAnimation } from '@/components/LottieAnimation';
import { useCartRequest } from '@/contexts/cart';
import { useOrder } from '@/contexts/order';

import { PairDeviceLayout } from './PairDeviceLayout';

export const CustomerNamePage = () => {
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');
  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');
  const [, setPaymentUuid] = useLocalStorage<null | string>('PAYMENT_UUID', null);
  const [, setPiSecret] = useLocalStorage('PI_SECRET', '');

  const navigate = useNavigate();

  const { setOrder } = useOrder();
  const cartRequest = useCartRequest();

  const { mutate: getTerminalStatus, isLoading: isTerminalAvailabilityCheckLoading } = useTerminalStatusMutation({
    onError: () => {
      navigate('/onSite/terminalDisconnected');
    },
  });

  const { mutate: prepareOrderOnSite, isLoading: isPrepareOrderLoading } = usePrepareOrderOnSiteMutation({
    onSuccess: (data, params) => {
      if (data.payment_uuid) {
        setPaymentUuid(data.payment_uuid);
      }
      setOrder(data.order);
      setPiSecret(data.pi_secret);
      navigate(`/onSite/paymentInstructions/${data.order.uuid}/${params.customerName}`);
    },
    onError: () => getTerminalStatus({ locationUuid, terminalUuid }),
  });

  useEffect(() => {
    getTerminalStatus({ locationUuid, terminalUuid });
  }, [getTerminalStatus, locationUuid, terminalUuid]);

  const { mutate: deleteAsyncPayment } = useDeleteOrderPaymentMutation();

  const handleSubmit = (customerName: string) => {
    const prepareOrderParams = {
      items: cartRequest.items,
      restaurantPlatformId: cartRequest.restaurant_platform_id,
      terminalUuid,
      customerName,
    };

    prepareOrderOnSite(prepareOrderParams);
  };

  const urlLocationState = useLocation().state;

  const backButtonUrl = useMemo(() => {
    if (urlLocationState?.backButtonUrl) return urlLocationState?.backButtonUrl;

    return -1;
  }, [urlLocationState]);

  return (
    <PairDeviceLayout headerTitle={t`Checkout`}>
      {isPrepareOrderLoading || isTerminalAvailabilityCheckLoading ? (
        <Stack alignItems="center">
          <LottieAnimation animation={cycleAnimation} sx={{ width: '250px' }} />
          <Heading as="span" fontWeight={700} fontSize={32}>
            <Trans>Processing order...</Trans>
          </Heading>
        </Stack>
      ) : (
        <Stack px={4} alignItems="center">
          <BackButton
            sx={{ alignSelf: 'flex-start' }}
            label={t`Back`}
            onClick={() => {
              deleteAsyncPayment({ terminalUuid, locationUuid });
              getTerminalStatus({
                locationUuid,
                terminalUuid,
              });
              navigate(backButtonUrl);
            }}
          />
          <CustomerNameForm onSubmit={handleSubmit} />
        </Stack>
      )}
    </PairDeviceLayout>
  );
};
