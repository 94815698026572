import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useState } from 'react';

import Card from '@/components/Card';
import { useOrder } from '@/contexts/order';
import { launchIntercom } from '@/helpers/intercom.helpers';
import { Review } from '@/types';

const ReviewButton = ({
  value,
  onClick,
  isSelected,
}: {
  value: string;
  onClick: () => unknown;
  isSelected: boolean;
}) => {
  return (
    <Card
      as="button"
      flexGrow="1"
      padding="0"
      border={`1px solid ${isSelected ? 'black' : 'transparent'}`}
      _hover={{ border: '1px solid black' }}
      onClick={onClick}
    >
      <Text fontSize="48px" textAlign="center">
        {value}
      </Text>
    </Card>
  );
};

type Props = {
  googleReviewUrl: string;
  onReview: (review: Review) => unknown;
  onGoogleReviewOpen: () => unknown;
  onIntercomOpen: () => unknown;
  onExtraStepClose: () => unknown;
};

const OrderReview = ({ googleReviewUrl, onReview, onGoogleReviewOpen, onIntercomOpen, onExtraStepClose }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const modalSize = useBreakpointValue({ base: 'xs', md: 'lg' });

  const { order, setOrder } = useOrder();
  const isReviewed = order?.isReviewed ?? false;
  const hasGoogleReview = order?.hasGoogleReview ?? false;

  const [review, setReview] = useState<Review | undefined>();

  const handleReview = (review: Review) => {
    setReview(review);
    onReview(review);
    onOpen();
  };

  const openGoogleReview = () => {
    onGoogleReviewOpen();
    handleClose({ hasGoogleReview: true, explicitClose: false });
    open(googleReviewUrl);
  };

  const openIntercom = () => {
    handleClose({ explicitClose: false });
    onIntercomOpen();
    launchIntercom();
  };

  const handleClose = (opts: { explicitClose?: boolean; hasGoogleReview?: boolean } = {}) => {
    const { explicitClose, hasGoogleReview } = { explicitClose: true, hasGoogleReview: false, ...opts };

    onClose();

    if (explicitClose) onExtraStepClose();
    if (order) setOrder((order) => order && { ...order, isReviewed: true, hasGoogleReview });
  };

  if (isReviewed) {
    if (hasGoogleReview) {
      return (
        <Text textAlign="center" textColor="green">
          <Trans>Thanks for your feedback!</Trans>
        </Text>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <Flex gap="4" flexDirection="column">
        <Stack textAlign="center" fontSize="18px">
          <Text fontWeight={500} fontSize={18}>
            <Trans>How was your order?</Trans>
          </Text>
        </Stack>
        <Flex gap="16px">
          <ReviewButton value="😢" isSelected={review === Review.BAD} onClick={() => handleReview(Review.BAD)} />
          <ReviewButton value="😄" isSelected={review === Review.GOOD} onClick={() => handleReview(Review.GOOD)} />
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={() => handleClose()} size={modalSize}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            <Text fontSize="60px" lineHeight="1">
              {review === Review.GOOD ? '💌' : '😢'}
            </Text>
            {review === Review.GOOD ? <Trans>Send a thank you note?</Trans> : <Trans>We’re sorry</Trans>}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack fontSize="18px" textAlign="center">
              {review === Review.GOOD ? (
                <Trans>Our chefs work hard to deliver the best food experience and love hearing from you.</Trans>
              ) : (
                <Stack spacing="4">
                  <Text>
                    <Trans>We’re sorry that your ordering experience was disappointing.</Trans>
                  </Text>
                  <Text>
                    <Trans>
                      Tell a support specialist if there’s anything we can do and we’ll do our best to resolve the
                      issue.
                    </Trans>
                  </Text>
                </Stack>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter display="flex" flexDirection="column" gap="2">
            {review === Review.GOOD ? (
              <Button w="100%" leftIcon={<ExternalLinkIcon />} onClick={() => openGoogleReview()}>
                <Trans>Leave a note on Google</Trans>
              </Button>
            ) : (
              <Button w="100%" onClick={() => openIntercom()}>
                <Trans>Contact a support specialist</Trans>
              </Button>
            )}
            <Button w="100%" variant="text" onClick={() => handleClose()}>
              <Trans>No thanks</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OrderReview;
