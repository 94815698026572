import { useCallback, useMemo } from 'react';

import { Menu, MenuElement, Order } from '@/api/types';

const useOrderItems = (order: Order | null, menu: Menu | undefined) => {
  const getMenuElementByUuid = useCallback(
    (uuid: string): MenuElement | undefined =>
      menu?.menuElements.find(({ menuItemUuid }: { menuItemUuid: string }) => menuItemUuid == uuid),
    [menu]
  );

  const orderItems = useMemo(
    () => order?.items.map((item) => ({ ...item, imageUrl: getMenuElementByUuid(item.uuid)?.imageUrl })),
    [order?.uuid, getMenuElementByUuid]
  );

  return { orderItems };
};

export default useOrderItems;
