import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useLocalStorage } from '@uidotdev/usehooks';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { boolean, object, string } from 'yup';

import { useSendCustomerEmailMutation } from '@/api/useSendCustomerEmailMutation';
import useFormatPrice from '@/hooks/useFormatPrice';

import { TermsAgreementCheckboxLabel } from './TermsAgreementCheckboxLabel';

interface CheckoutFormProps {
  price: number;
  currency: string;
  isPreOrder: boolean;
  isTooLateForPayment: boolean;
  orderUuid: string;
  paymentMethodSelector?: React.ReactNode;
  onPay: () => void;
}

const checkoutFormValidationSchema = object({
  name: string().required(),
  areTermsAccepted: boolean().test((value) => !!value),
  email: string().email(),
  isOptedIn: boolean(),
});

const CheckoutForm = ({
  price,
  currency,
  isPreOrder,
  isTooLateForPayment,
  orderUuid,
  paymentMethodSelector,
  onPay,
}: CheckoutFormProps) => {
  const { formatPrice } = useFormatPrice();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | undefined>();
  const [paymentUuid] = useLocalStorage<string | null>('PAYMENT_UUID', null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      areTermsAccepted: false,
      email: '',
      isOptedIn: false,
      name: '',
    },
    mode: 'all',
    resolver: yupResolver(checkoutFormValidationSchema),
  });

  const handleStripePayment = async ({ name, email }: { name: string; email?: string }) => {
    if (!stripe || !elements) return;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/confirmPayment`,
        payment_method_data: { billing_details: { name, email } },
      },
    });

    if (error) {
      setMessage(error.message);
    }
  };

  const { isLoading, mutate: sendCustomerEmail } = useSendCustomerEmailMutation();

  const submitCheckoutForm = handleSubmit(async (formData) => {
    setIsPaymentLoading(true);
    onPay();

    if (!paymentUuid) return;

    const { email, isOptedIn, name } = formData;

    if (!isPreOrder) {
      handleStripePayment({ email, name });

      return;
    }

    sendCustomerEmail(
      { customerEmail: email, orderUuid, paymentUuid, marketingConsent: isOptedIn },
      {
        onError: () => {
          setMessage(t`Preordering is unavailable right now. Please try again and place your order for pickup ASAP.`);
        },
        onSettled: () => {
          setIsPaymentLoading(false);
        },
        onSuccess: () => handleStripePayment({ name, email }),
      }
    );
  });

  const isPaymentButtonDisabled = !stripe || !elements || !isValid || isTooLateForPayment;

  return (
    <form onSubmit={submitCheckoutForm}>
      {/* FIXME: Harmonize Stripe CSS with our own */}
      <Stack gap={1} my={2}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <FormControl mb={3} isRequired>
              <FormLabel>
                <Trans>Name</Trans>
              </FormLabel>
              <Input {...field} />
              <FormHelperText>
                <Trans>To pickup your order </Trans>
              </FormHelperText>
            </FormControl>
          )}
        />
        {isPreOrder && (
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormControl mb={3} isRequired>
                <FormLabel>
                  <Trans>Email</Trans>
                </FormLabel>
                <Input type="email" {...field} />
                <FormHelperText>
                  <Trans>To get a receipt</Trans>
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
        <PaymentElement />
        {message && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            {message}
          </Alert>
        )}
        <Controller
          name="areTermsAccepted"
          control={control}
          render={({ field: { value, ...fieldRest } }) => (
            <FormControl isRequired>
              <Checkbox {...fieldRest} spacing={6} isChecked={value}>
                <TermsAgreementCheckboxLabel />
              </Checkbox>
            </FormControl>
          )}
        />
        {isPreOrder && (
          <Controller
            name="isOptedIn"
            control={control}
            render={({ field: { value, ...fieldRest } }) => (
              <Checkbox isChecked={value} spacing={6} {...fieldRest}>
                <Trans>Yes, I would like to be the first to know about exclusive offers and discounts.</Trans>
              </Checkbox>
            )}
          />
        )}
      </Stack>
      <Button
        size="lg"
        w="100%"
        mt={4}
        isDisabled={isPaymentButtonDisabled}
        isLoading={isLoading || isPaymentLoading}
        type="submit"
      >
        <Trans>Pay {formatPrice(price, currency)}</Trans>
      </Button>
      {paymentMethodSelector}
      {isTooLateForPayment && (
        <>
          <Text color="gray.disabled" pt="3">
            <Trans>The pickup time you selected has passed.</Trans>
          </Text>
          <Button variant="link" onClick={() => navigate(-1)}>
            <Trans>Choose a new time instead</Trans>
          </Button>
        </>
      )}
    </form>
  );
};

export default CheckoutForm;
