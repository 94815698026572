import { Stack } from '@chakra-ui/react';

import { KioskAbandonCartButton } from '@/components/Kiosk/KioskAbandonCartButton';
import { KioskCartButton } from '@/components/Kiosk/KioskCartButton';

export interface FooterProps {
  itemsQuantity: number;
  onCartClick: () => void;
}

export const Footer = ({ itemsQuantity, onCartClick }: FooterProps) => {
  return (
    <Stack
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 2,
        height: { md: '111px' },
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 2,
        p: 4,
        background: 'white',
        boxShadow: '0px -4px 16px 0px #0000001A',
      }}
    >
      <KioskAbandonCartButton />
      <KioskCartButton
        to="cart"
        onClick={onCartClick}
        sx={{
          marginLeft: 'auto',
          position: { base: 'unset', md: 'absolute' },
          right: 4,
        }}
        itemsQuantity={itemsQuantity}
      />
    </Stack>
  );
};
