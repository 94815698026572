import { Box, Button, Heading, Image } from '@chakra-ui/react';

import ArrowBack from '@/assets/img/arrow-left.svg';
import { SettingsAccessButton } from '@/components/PairingTerminal/SettingsAccessButton';

const titleDescriptionStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
};

export interface MenuItemsPageHeaderProps {
  title: string;
  onBackButtonClick?: () => void;
}

export const MenuItemsPageHeader = ({ title, onBackButtonClick }: MenuItemsPageHeaderProps) => {
  return (
    <Box
      backgroundColor="#FFFFFF"
      position="sticky"
      top="0"
      zIndex={1}
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="0px 4px 16px 0px #0000001A"
    >
      <Button
        top={0}
        left={4}
        variant="outline"
        borderColor="gray.700"
        color="gray.700"
        height="64px"
        width="64px"
        onClick={onBackButtonClick}
      >
        <Image src={ArrowBack} />
      </Button>
      <Heading as="h4" size="md" fontWeight="500" textAlign="center" maxW={'100%'} sx={titleDescriptionStyle}>
        {title}
      </Heading>
      <SettingsAccessButton />
    </Box>
  );
};
