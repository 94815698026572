import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import wheelAnimation from '@/assets/anim/lottie_wheel.json';

import { LottieAnimation } from './LottieAnimation';

const PongoWheel = ({ pongoUrl, onClick }: { pongoUrl: string; onClick: () => unknown }) => {
  const handleClick = () => {
    onClick();
    open(pongoUrl);
  };

  if (!pongoUrl) {
    return <></>;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="2">
      <LottieAnimation animation={wheelAnimation} />
      <Text textAlign="center" fontSize="18px">
        <Trans>
          Feeling lucky?
          <br /> Spin the wheel to win a free item!
        </Trans>
      </Text>
      <Button leftIcon={<ExternalLinkIcon />} w="100%" onClick={handleClick}>
        <Trans>Spin the wheel</Trans>
      </Button>
    </Box>
  );
};

export default PongoWheel;
