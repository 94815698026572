import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  Icon,
  IconButton,
  IconButtonProps,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { AiOutlineInfo } from 'react-icons/ai';

import useFormatPrice from '@/hooks/useFormatPrice';

export interface MenuItemDetailsButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  currencyCode: string;
  description: string;
  label: string;
  onAddItem: () => void;
  pictureUrl?: string;
  price: number;
}

export const MenuItemDetailsButton = ({
  currencyCode,
  description,
  label,
  onAddItem,
  onClick,
  pictureUrl,
  price,
  ...iconButtonProps
}: MenuItemDetailsButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formatPrice } = useFormatPrice();
  const formattedPrice = formatPrice(price, currencyCode);

  const handleAddToCart = () => {
    onClose();
    onAddItem();
  };

  return (
    <>
      <IconButton
        aria-label={t`Product information`}
        colorScheme="blackAlpha"
        icon={<Icon as={AiOutlineInfo} width="24px" height="24px" color="white" />}
        isRound
        variant="solid"
        onClick={(event) => {
          onClick?.(event);
          onOpen();
        }}
        {...iconButtonProps}
      />
      <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'xs', sm: 'sm', md: 'xl' }}>
        <ModalOverlay />
        <ModalContent textAlign="center" borderRadius="2xl" overflow="hidden" gap={6}>
          <ModalHeader padding={0} display="flex" flexDirection="column" gap={8} position="relative">
            <IconButton
              aria-label={t`Close item details`}
              colorScheme="gray"
              icon={<CloseIcon />}
              position="absolute"
              right={6}
              top={6}
              onClick={onClose}
              border="1px solid"
              borderColor="gray.300"
              backgroundColor="white"
              size="lg"
            />
            <Image aria-hidden src={pictureUrl} maxWidth="700px" width="100%" />
            <Heading alignSelf="center" size="xl">
              {label}
            </Heading>
          </ModalHeader>
          <ModalBody alignItems="center" display="flex" flexDirection="column" paddingX={6} paddingY={0} gap={6}>
            {description && <Text fontSize="lg">{description}</Text>}
            <Tag border="3px solid" borderColor="gray.300" borderRadius="full" size="lg">
              <TagLabel color="black" fontSize="2xl" fontWeight="700">
                {formattedPrice}
              </TagLabel>
            </Tag>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center" paddingX={6} paddingBottom={14}>
            <Button colorScheme="black" onClick={handleAddToCart} size="xl">
              + <Trans>Add to cart</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
