import { Avatar, HStack, Skeleton, SkeletonCircle, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useGetKitchenInfosQuery } from '@/api/useGetKitchenInfos';
import { useTerminalStatusMutation } from '@/api/useTerminalStatusMutation';
import TasterIcon from '@/assets/img/taster_circle_logo.svg';
import { AsyncContent } from '@/components/AsyncContent';
import Card from '@/components/Card';
import { PairTerminalButton } from '@/components/PairingTerminal/PairTerminalButton';
import { TerminalStatusCard } from '@/components/PairingTerminal/TerminalStatusCard';

import { PairDeviceLayout } from './PairDeviceLayout';

interface UserInfosProps {
  locationUuid: string;
}

const UserInfos = ({ locationUuid }: UserInfosProps) => {
  const { keycloak } = useKeycloak();

  const { data: userInfo } = useQuery<{ email?: string }, void>(['loadUserInfo'], () => keycloak.loadUserInfo());

  const {
    data: kitchenInfos,
    isLoading: isKitchenInfoLoading,
    isError,
  } = useGetKitchenInfosQuery({
    locationUuid,
  });

  const kitchenLabel = useMemo(() => {
    if (!kitchenInfos) return;

    return kitchenInfos?.label;
  }, [kitchenInfos]);

  return (
    <AsyncContent
      data={{ kitchenLabel, userInfo }}
      isError={isError}
      isLoading={isKitchenInfoLoading}
      LoadingContent={
        <Stack gap={2} alignItems="center">
          <SkeletonCircle size="50" />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Stack>
      }
      hasData={!!(userInfo?.email && kitchenLabel)}
      renderContent={({ kitchenLabel, userInfo }) => (
        <Stack alignItems="center">
          <Avatar
            src={TasterIcon}
            sx={{
              background: 'white',
              border: `1px solid`,
              borderColor: 'gray.300',
              width: '96px',
              height: '96px',
            }}
          />
          <Text fontWeight={700} fontSize={24} color="gray.700">
            {kitchenLabel}
          </Text>
          {!!userInfo?.email && (
            <Text fontSize={20} color="gray.600">
              {userInfo.email}
            </Text>
          )}
        </Stack>
      )}
    />
  );
};

interface DevicesInfoProps {
  deviceId?: string;
  cardMachineId?: string;
}

const DevicesInfo = ({ deviceId, cardMachineId }: DevicesInfoProps) => {
  return (
    <Card>
      <Stack width="100%">
        <HStack justifyContent="space-between">
          <Text fontSize={20}>
            <Trans>Device ID</Trans>
          </Text>
          <Text fontSize={20}>{deviceId ?? t`Unknown`}</Text>
        </HStack>
        <HStack justifyContent="space-between">
          <Text fontSize={20}>
            <Trans>Card machine ID</Trans>
          </Text>
          <Text textAlign="end" fontSize={20}>
            {cardMachineId ?? t`Unknown`}
          </Text>
        </HStack>
      </Stack>
    </Card>
  );
};

export const TerminalSettingsPage = () => {
  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');
  const [keyAuthId] = useLocalStorage<string | null>('KEY_AUTH_ID', null);
  const [terminalUuid] = useLocalStorage<string | null>('TERMINAL_UUID', null);
  const [isMockFetchStatusLoading, setIsMockFetchStatusLoading] = useState(false);

  const { mutate: getTerminalStatus, isError, isLoading, data: terminalStatus } = useTerminalStatusMutation();

  const mockGetTerminalStatus = () => {
    setIsMockFetchStatusLoading(true);

    setTimeout(() => {
      setIsMockFetchStatusLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (locationUuid && terminalUuid) {
      getTerminalStatus({ locationUuid, terminalUuid });
    }
  }, [getTerminalStatus, locationUuid, terminalUuid]);

  if (!(terminalUuid && keyAuthId && locationUuid)) return <Navigate to="/onSite/internalLocations" />;

  const handleRefreshClick = (locationUuid?: string, terminalUuid?: string) => {
    if (locationUuid && terminalUuid) {
      getTerminalStatus({ locationUuid, terminalUuid });
    } else {
      mockGetTerminalStatus();
    }
  };

  return (
    <PairDeviceLayout
      hasLogOutButton
      headerTitle={t`Settings`}
      hasBackButton
      url={`/onSite/${locationUuid}`}
      backButtonText={t`Back to menu`}
    >
      <Stack gap={4} maxW={700} justifyContent="center" mx="auto" px={2}>
        <UserInfos locationUuid={locationUuid} />
        <HStack justifyContent="space-between">
          <Text fontSize={20}>
            <Trans>Device Information</Trans>
          </Text>
          <PairTerminalButton variant="outline" sx={{ background: 'white', borderColor: 'gray.300' }} />
        </HStack>
        <AsyncContent
          data={terminalStatus}
          isLoading={isLoading || isMockFetchStatusLoading}
          isError={isError}
          hasData={!!terminalStatus && !isMockFetchStatusLoading}
          LoadingContent={
            <Stack gap={2}>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Stack>
          }
          renderContent={({ status, locationUuid, terminalUuid }) => (
            <>
              <TerminalStatusCard
                terminalStatus={status}
                terminalUuid={terminalUuid}
                locationUuid={locationUuid}
                onRefreshClick={handleRefreshClick}
              />
              <DevicesInfo cardMachineId={terminalUuid} deviceId={keyAuthId} />
            </>
          )}
        />
      </Stack>
    </PairDeviceLayout>
  );
};
