import { chakra, Grid, Stack, Text } from '@chakra-ui/react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Category } from '@/api/gateway-click-collect/restaurants/types';
import { KioskMenuCard } from '@/components/Kiosk/KioskMenuCard';
import { useCart } from '@/contexts/cart';
import { useCategoriesDataContext } from '@/contexts/CategoriesDataProvider';
import { useOnSiteSession } from '@/contexts/onSiteSession';
import dataLayer from '@/helpers/dataLayer.helpers';
import { Footer } from '@/routes/PayOnSite/MenuCategoriesPage/Footer';

import { Header } from './Header';

const StyledGrid = chakra(Grid, {
  baseStyle: {
    gap: 3,
    width: '100%',
    justifyContent: 'center',
    paddingBottom: 20,
    px: 3,
    gridTemplateColumns: { base: '1fr', sm: 'repeat(2, minmax(200px, 432px))' },
    '@media screen and (min-width: 1366px) and (max-height: 1024px)': {
      gridTemplateColumns: 'repeat(3, minmax(200px, 432px));',
    },
  },
});

export const MenuCategoriesPage = () => {
  const { restaurantPlatformId } = useParams() as { restaurantPlatformId: string };
  const navigate = useNavigate();
  const { startOnSiteSession } = useOnSiteSession();

  const { cart } = useCart();
  const itemsQuantity = cart.items.reduce((quantityAcc, item) => quantityAcc + item.quantity, 0);
  const hasItems = !!itemsQuantity;
  const { categories, conceptInformation } = useCategoriesDataContext();

  const [kitchenLabel] = useLocalStorage('KITCHEN_LABEL', '');
  const [locationUuid] = useLocalStorage('LOCATION_UUID', '');
  const [terminalUuid] = useLocalStorage('TERMINAL_UUID', '');

  useEffect(() => {
    startOnSiteSession({
      kitchenLabel,
      terminalUuid,
    });
  }, [kitchenLabel, startOnSiteSession, terminalUuid]);

  const navigateToCategoryMenuItems = (category: Category) => {
    dataLayer.logOrderingKioskCategoryClick({
      brandLabel: conceptInformation.conceptName,
      categoryName: category.label,
      kitchenLabel,
    });

    window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
    navigate(`/onSite/menu/${restaurantPlatformId}/${category.uuid}`);
  };

  return (
    <Stack justifyContent="center" alignItems="center" pb={12} minHeight={0} gap={3}>
      <Header conceptInformation={conceptInformation} locationUuid={locationUuid} />
      <Stack px={4} gap={3} justifyContent="center">
        {categories && (
          <StyledGrid>
            {categories.map((category) => {
              const { uuid, label, picture } = category;

              return (
                <KioskMenuCard
                  label={label}
                  key={uuid}
                  pictureUrl={picture}
                  onClick={() => navigateToCategoryMenuItems(category)}
                >
                  <Text fontSize={{ base: '20px', xl: '24px' }} textAlign="center" py={3}>
                    {label}
                  </Text>
                </KioskMenuCard>
              );
            })}
          </StyledGrid>
        )}
      </Stack>
      {hasItems && (
        <Footer
          itemsQuantity={itemsQuantity}
          onCartClick={() => {
            dataLayer.clickCart({ brandLabel: conceptInformation.conceptName, kitchenLabel, orderChannel: 'onsite' });
          }}
        />
      )}
    </Stack>
  );
};
