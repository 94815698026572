import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Alert, AlertDescription } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

import { getWebViewType } from '@/helpers/webview';

const WebviewMessage = () => {
  const webviewType = getWebViewType();

  if (webviewType !== 'webview_ios') return <></>;

  return (
    <>
      <Alert status="warning" pl={3}>
        <ExternalLinkIcon boxSize={5} mr={3} />
        <AlertDescription fontSize={14}>
          <Trans>To complete your order, open this page in your browser.</Trans>
        </AlertDescription>
      </Alert>
    </>
  );
};

export default WebviewMessage;
