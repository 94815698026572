import { Box, Image, Stack } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { PropsWithChildren } from 'react';

import Card from '@/components/Card';

interface KioskMenuCardProps {
  onClick: () => void;
  label: string;
  pictureUrl?: string;
}

export const KioskMenuCard = ({ onClick, label, pictureUrl, children }: PropsWithChildren<KioskMenuCardProps>) => {
  return (
    <Box
      sx={{
        '&:active': {
          border: '6px solid #333333',
        },
        height: '100%',
        border: '6px solid transparent',
        transition: 'border 0.3s',
        borderRadius: 24,
      }}
    >
      <Card
        as="button"
        height="100%"
        border="1px solid #D9D9D9"
        borderRadius={16}
        boxShadow="0px 4px 16px 0px #0000001A"
        onClick={onClick}
        p={0}
      >
        <Stack height="100%" justifyContent="center" borderRadius={16}>
          <Image objectFit="cover" borderRadius="16px 16px 0px 0px" src={pictureUrl ?? t`Unknown`} alt={label} />
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            p={3}
            flexDirection="column"
            height="inherit"
            justifyContent="center"
          >
            {children}
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};
