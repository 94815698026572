import ApiError from '@/api/ApiError';
import { Cart as CartV2 } from '@/api/gateway-click-collect/orders';
import { Cart, ItemCart } from '@/types';

interface ModifierOutOfStock {
  errorType: 'modifier-oos';
  modifierUuid: string;
}

interface ItemOutOfStock {
  errorType: 'item-oos';
  menuItemUuid: string;
}

export type OutOfStocks = ItemOutOfStock | ModifierOutOfStock;

export const getOutOfStocks = (error: ApiError | null): OutOfStocks | null => {
  if (!error?.body) return null;

  const { errorType } = error.body;

  switch (errorType) {
    case 'modifier-oos':
    case 'item-oos': {
      return error.body;
    }
    default: {
      return null;
    }
  }
};

export const formatCartForValidation = (cart: Cart, terminalUuid?: string): CartV2 => {
  const { restaurantPlatformId, items, pickupTime } = cart;

  return {
    terminalUuid,
    restaurantPlatformId,
    items: items.map(({ menuItemUuid, quantity, selectedModifierGroups = [] }) => {
      return {
        menuItemUuid,
        modifierGroups: selectedModifierGroups
          .filter(({ selectedModifiers }) => !!selectedModifiers?.length)
          .map(({ modifierGroupUuid, selectedModifiers = [] }) => {
            return {
              modifierGroupUuid,
              modifiers: selectedModifiers.map(
                ({ menuItemUuid, modifierUuid, quantity, selectedModifierGroups = [] }) => {
                  return {
                    menuItemUuid,
                    modifierUuid,
                    quantity,
                    modifierGroups: selectedModifierGroups
                      .filter(({ selectedModifiers }) => !!selectedModifiers?.length)
                      .map(({ modifierGroupUuid, selectedModifiers = [] }) => {
                        return {
                          modifierGroupUuid,
                          modifiers: selectedModifiers.map(({ menuItemUuid, modifierUuid, quantity }) => {
                            return {
                              menuItemUuid,
                              modifierUuid,
                              quantity,
                            };
                          }),
                        };
                      }),
                  };
                }
              ),
            };
          }),
        quantity,
      };
    }),
    pickupTime: pickupTime ? new Date(pickupTime) : undefined,
  };
};

export const getCartPrice = (items: ItemCart[]) => {
  return items.reduce((cartPriceAcc, item) => {
    const modifiersPrice = (item.selectedModifierGroups ?? [])
      .flatMap(({ selectedModifiers }) => selectedModifiers)
      .reduce((modifiersPriceAcc, modifier) => {
        if (modifier) {
          const subModifiersPrice = (modifier.selectedModifierGroups ?? [])
            .flatMap(({ selectedModifiers }) => selectedModifiers)
            .reduce((subModifiersPriceAcc, subModifier) => {
              if (subModifier) {
                const subModifierPrice = subModifier.quantity * subModifier.sellingPrice;

                return subModifiersPriceAcc + subModifierPrice;
              }

              return subModifiersPriceAcc;
            }, 0);
          const modifierPrice = modifier.quantity * (modifier.sellingPrice + subModifiersPrice);

          return modifiersPriceAcc + modifierPrice;
        }

        return modifiersPriceAcc;
      }, 0);
    const itemPrice = item.quantity * (item.sellingPrice + modifiersPrice);

    return cartPriceAcc + itemPrice;
  }, 0);
};
