import { HStack, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import dayjs from 'dayjs';

import { Shift } from '@/api/types';
interface ShiftHoursProps {
  day: string;
  shiftsList: Shift[];
}

export const RestaurantInfoShift = ({ day, shiftsList }: ShiftHoursProps) => {
  const isoWeekDay = dayjs()
    .isoWeekday(Number(day) + 1)
    .format('dddd');
  return (
    <HStack align="baseline" maxWidth={600}>
      <Text fontWeight={700} flex={1} textAlign={'left'}>
        {isoWeekDay}
      </Text>
      <Stack spacing={0} flex={3} textAlign={'right'}>
        {shiftsList.length ? (
          shiftsList.map((shift, index) => {
            return (
              <Text key={index}>
                {shift.shiftStart} - {shift.shiftEnd}
              </Text>
            );
          })
        ) : (
          <Text>
            <Trans>Closed</Trans>
          </Text>
        )}
      </Stack>
    </HStack>
  );
};
