import { t } from '@lingui/macro';
import dayjs, { Dayjs } from 'dayjs';

import { OnlineStatus, OpeningHours, RestaurantShift } from '@/api/gateway-click-collect/restaurants/types';

const getFormattedShiftList = (shiftList: RestaurantShift[]) =>
  shiftList.map(({ endTime, startTime }) => {
    if (!startTime || !endTime) return;

    const [startTimeHour, startTimeMinute] = startTime.split(':');
    const [endTimeHour, endTimeMinute] = endTime.split(':');

    const endTimeDay = endTimeHour === '00' ? dayjs().add(1, 'day') : dayjs();

    return {
      startTime: dayjs().hour(Number(startTimeHour)).minute(Number(startTimeMinute)),
      endTime: endTimeDay.hour(Number(endTimeHour)).minute(Number(endTimeMinute)),
    };
  }) ?? [];

const getClosesAt = (isOpenNow: boolean, todayShiftsList: RestaurantShift[]) => {
  if (!isOpenNow) return;

  const currentShift = todayShiftsList.find(({ startTime, endTime }) =>
    dayjs().isBetween(dayjs(startTime), dayjs(endTime))
  );

  if (!currentShift) return;

  return dayjs(currentShift.endTime);
};

const getParsedOpensAt = (todayShiftsList: RestaurantShift[], closesAt?: Dayjs) => {
  if (closesAt) return;

  const remainingTodayOpeningTimes = todayShiftsList
    .map(({ startTime }) => startTime)
    .filter((openingTime) => dayjs(openingTime).isAfter(dayjs()));

  return remainingTodayOpeningTimes.reduce(
    (openingTimeA, openingTimeB) =>
      dayjs().diff(dayjs(openingTimeA)) < dayjs().diff(dayjs(openingTimeB)) ? openingTimeA : openingTimeB,
    null
  );
};

const getTodayShift = (todayShiftsList: RestaurantShift[]) =>
  todayShiftsList.map(({ endTime, startTime }) => ({
    shiftStart: startTime,
    shiftEnd: endTime,
  }));

const getOpeningLabel = (isOpenNow: boolean, closesAt?: Dayjs, parsedOpensAt?: string | null) => {
  if (isOpenNow && closesAt) {
    return t`Open until ${dayjs(closesAt).format('LT')}`;
  } else if (parsedOpensAt) {
    return t`Opens at ${dayjs(parsedOpensAt).format('LT')}`;
  }
};

const getIsOpenNow = ({
  shiftList,
  status,
}: {
  shiftList: ({ startTime: Dayjs; endTime: Dayjs } | undefined)[];
  status: OnlineStatus;
}) =>
  status !== 'close' &&
  !!shiftList.length &&
  shiftList.some((shift) => !!shift && dayjs().isBetween(shift.startTime, shift.endTime));

export const formatOnsiteOpeningHours = ({
  restaurantOpeningHours,
  status,
}: {
  restaurantOpeningHours: OpeningHours;
  status: OnlineStatus;
}) => {
  const weekdayIndex = dayjs().isoWeekday() - 1;
  const todayShiftsList = restaurantOpeningHours.weekdays[weekdayIndex]?.shifts ?? [];

  const formattedTodayShiftList = getFormattedShiftList(todayShiftsList);

  const isOpenNow = getIsOpenNow({ shiftList: formattedTodayShiftList, status });

  const closesAt = getClosesAt(isOpenNow, todayShiftsList);

  const parsedOpensAt = getParsedOpensAt(todayShiftsList, closesAt);

  return {
    closesAt,
    isOpenNow,
    isOpenToday: !!todayShiftsList.length,
    opensAt: parsedOpensAt,
    shifts: getTodayShift(todayShiftsList),
    openingLabel: getOpeningLabel(isOpenNow, closesAt, parsedOpensAt),
  };
};
