import { setTag } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { getMenu } from '@/api/api';
import ApiError from '@/api/ApiError';
import { Menu } from '@/api/types';
import useOpeningHours from '@/hooks/useOpeningHours';

const useMenu = (restaurantPlatformId?: string) => {
  const { data: menu, ...queryResult } = useQuery<Menu, ApiError>(
    ['menu', restaurantPlatformId],
    () => getMenu(restaurantPlatformId!),
    {
      enabled: !!restaurantPlatformId,
      onSuccess: (menu) => {
        setTag('location_uuid', menu.locationUuid);
        setTag('restaurant_label', menu.restaurantName);
      },
    }
  );

  const openingHours = useOpeningHours(menu?.shifts ?? [], menu?.opensAt);

  return { menu, ...queryResult, ...openingHours };
};

export default useMenu;
