import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, IconButton, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
  defaultValue: number;
  isDisabled: boolean;
  label?: string;
  min?: number;
  size?: string;
  fontSize?: string;
  onDecrement?: (number: number) => void;
  onIncrement?: (number: number) => void;
}

const NumberInput = ({
  min,
  defaultValue,
  onDecrement,
  onIncrement,
  isDisabled,
  label,
  size = 'xs',
  fontSize = 'md',
}: Props) => {
  const [number, setNumber] = useState<number>(defaultValue);

  const decrementValue = () => {
    const newNumber = number - 1;
    setNumber(newNumber);
    if (onDecrement) {
      onDecrement(newNumber);
    }
  };

  const incrementValue = () => {
    const newNumber = number + 1;
    setNumber(newNumber);
    if (onIncrement) {
      onIncrement(newNumber);
    }
  };

  return (
    <Box display="flex" m="auto" alignItems="center" flexDirection="column" maxW="320">
      {label && (
        <Text fontSize="xl" fontWeight={500}>
          {label}
        </Text>
      )}
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt={2}>
        <IconButton
          aria-label="Moins 1"
          borderRadius="50%"
          borderWidth={2}
          icon={<MinusIcon />}
          variant="outline"
          size={size}
          isDisabled={number === min}
          onClick={decrementValue}
        />
        <Text fontSize={fontSize} width="3ch" textAlign="center">
          {number}
        </Text>
        <IconButton
          aria-label="Plus 1"
          borderRadius="50%"
          borderWidth={2}
          icon={<AddIcon />}
          isDisabled={isDisabled}
          onClick={incrementValue}
          size={size}
          variant="outline"
        />
      </Box>
    </Box>
  );
};

export default NumberInput;
