import { Button, chakra, Grid, Icon, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useState } from 'react';
import { HiHome } from 'react-icons/hi';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { MenuElement } from '@/api/types';
import ItemDrawer from '@/components/ItemDrawer';
import { MenuItemCard } from '@/components/Kiosk/MenuItemCard';
import { MenuItemsPageFooter } from '@/components/Kiosk/MenuItemsPageFooter';
import { MenuItemsPageHeader } from '@/components/Kiosk/MenuItemsPageHeader';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import { updateCart } from '@/helpers/menu.helpers';
import { useCategoryDataContext } from '@/routes/PayOnSite/MenuItemsPage/CategoryDataProvider';
import { ItemCart } from '@/types';

const StyledGrid = chakra(Grid, {
  baseStyle: {
    gridTemplateColumns: { base: '1fr', sm: 'repeat(2, minmax(200px, 432px))' },
    gap: 3,
    justifyContent: 'center',
    px: 3,
    pt: 4,
    pb: 125,
    '@media screen and (min-width: 1366px) and (max-height: 1024px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 432px));',
    },
  },
});

export const MenuItemsPage = () => {
  const navigate = useNavigate();
  const { restaurantPlatformId } = useParams() as { categoryUuid: string; restaurantPlatformId: string };
  const { isOpen: isAddItemToCartOpen, onOpen: onOpenAddItem, onClose: onCloseAddItem } = useDisclosure();
  const [selectedMenuElement, setSelectedMenuElement] = useState<MenuElement | null>(null);
  const { cart, setCart } = useCart();
  const [kitchenLabel] = useLocalStorage<string>('KITCHEN_LABEL', '');

  const { category, restaurant, conceptInformation, menuElements } = useCategoryDataContext();
  const { address, currencyCode, kitchenInstructions } = restaurant;

  const getCartItemQuantity = (itemUuid: string) => {
    return cart.items
      .filter(({ menuItemUuid }) => itemUuid === menuItemUuid)
      .reduce((quantityAcc, currentItem) => currentItem.quantity + quantityAcc, 0);
  };

  const onUpdateCart = (item: ItemCart) => {
    const items = cart.restaurantPlatformId === restaurantPlatformId ? updateCart({ item, cart }) : [item];

    dataLayer.logItemAdded(kitchenLabel, conceptInformation.conceptName, item, 'onsite');

    setCart({
      accessInstructions: kitchenInstructions,
      conceptLabel: conceptInformation.conceptName,
      items,
      kitchenLabel,
      restaurantAddress: address,
      restaurantName: restaurant.restaurantLabel,
      restaurantPlatformId,
    });

    onCloseAddItem();
  };

  const onItemClick = (menuElement: MenuElement) => {
    if (menuElement.modifierGroups.length) {
      setSelectedMenuElement(menuElement);
      onOpenAddItem();
    } else {
      onUpdateCart({ ...menuElement, quantity: 1 });
    }
  };

  return (
    <Stack height="100%">
      <MenuItemsPageHeader
        title={category.label}
        onBackButtonClick={() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
          navigate(`/onSite/menu/${restaurantPlatformId}`);
        }}
      />
      {menuElements?.length ? (
        <StyledGrid>
          {menuElements.map((menuElement) => {
            const { description, label, menuItemUuid, sellingPrice, imageUrl } = menuElement;

            return (
              <MenuItemCard
                currencyCode={currencyCode}
                description={description}
                key={menuItemUuid}
                label={label}
                onClick={() => onItemClick(menuElement)}
                pictureUrl={imageUrl}
                quantity={getCartItemQuantity(menuItemUuid)}
                sellingPrice={sellingPrice}
              />
            );
          })}
        </StyledGrid>
      ) : (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <Text fontSize="2xl" fontWeight={700}>
            <Trans>Sorry, all items are out of stock.</Trans>
          </Text>
          <Text fontSize="2xl" fontWeight={700}>
            <Trans>Please choose another category.</Trans>
          </Text>
          <Button
            as={Link}
            colorScheme="black"
            fontWeight={700}
            leftIcon={<Icon as={HiHome} marginBottom={0.5} />}
            marginTop={4}
            size="lg"
            textTransform="uppercase"
            to={`/onSite/menu/${restaurantPlatformId}`}
            variant="solid"
          >
            <Trans>Home</Trans>
          </Button>
        </Stack>
      )}
      <MenuItemsPageFooter
        cart={cart}
        restaurantPlatformId={restaurantPlatformId}
        onCartClick={() => {
          dataLayer.clickCart({ brandLabel: conceptInformation.conceptName, kitchenLabel, orderChannel: 'onsite' });
        }}
      />
      {!!selectedMenuElement && (
        <ItemDrawer
          currencyCode={currencyCode}
          disabledReason={t`This restaurant is currently closed.`}
          isOpen={isAddItemToCartOpen}
          isSubmitDisabled={false}
          menuElement={selectedMenuElement}
          onAddToCart={onUpdateCart}
          onClose={onCloseAddItem}
        />
      )}
    </Stack>
  );
};
