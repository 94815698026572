import { Flex, Heading, Image, Spinner } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import paymentAcceptedImgSrc from '@/assets/img/payment_accepted.png';
import paymentRejectedImgSrc from '@/assets/img/payment_rejected.png';
import footerLogo from '@/assets/img/taster_footer.png';
import { useCart } from '@/contexts/cart';
import { useOrder } from '@/contexts/order';
import dataLayer from '@/helpers/dataLayer.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import AppLayout from '@/layouts/AppLayout';

const Success = () => {
  return (
    <>
      <Heading>
        <Image src={paymentAcceptedImgSrc} />
        <Trans>Payment accepted</Trans>
      </Heading>
      <p>
        <Trans>Redirecting you to your order</Trans>
      </p>
    </>
  );
};

const Failure = () => {
  return (
    <>
      <Image w={216} h={216} margin="auto" src={paymentRejectedImgSrc} mt="30%" />
      <Heading>
        <Trans>Payment refused</Trans>
      </Heading>
      <p>
        <Trans>Redirecting you to your cart</Trans>
      </p>
    </>
  );
};

const ConfirmPaymentPage = () => {
  const navigate = useNavigate();

  const [piSecret] = useLocalStorage<string | null>('PI_SECRET', null);

  const { order } = useOrder();
  const { cart, resetCart } = useCart();
  const [status, setStatus] = useState<string | null>();

  useEffect(() => {
    const status = new URLSearchParams(window.location.search).get('redirect_status');

    const timeoutId = setTimeout(() => {
      if (status === 'succeeded') {
        if (order && piSecret)
          dataLayer.logPaymentSucceeded(cart.kitchenLabel, cart.conceptLabel, order, piSecret, 'online');

        resetCart();
        navigate(`/orderStatus/${order?.uuid}`);
      } else {
        navigate(`/menu/${order?.restaurant_platform_id}`);
      }
    }, 3000);

    setStatus(status);

    return () => clearTimeout(timeoutId);
  }, [cart, navigate, order, piSecret, resetCart]);

  usePageViewTracker(cart.kitchenLabel, cart.conceptLabel);

  return (
    <AppLayout title={t`Payment`}>
      <Flex justifyContent="center">
        <Flex gap="4" direction="column">
          {status !== null && status !== 'succeeded' && <Failure />}
          {status === 'succeeded' && <Success />}
          <Flex alignItems="center" justifyContent="center">
            <Spinner size="xl" />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        position="absolute"
        bottom={6}
        color="gray.disabled"
        fontWeight={500}
        justifyContent="center"
        alignItems="center"
        left={0}
        right={0}
      >
        <Image src={footerLogo} alt="Taster" width={350} />
      </Flex>
    </AppLayout>
  );
};

export default ConfirmPaymentPage;
