import { UseQueryOptions } from '@tanstack/react-query';

import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery';

import { fetchInternUserLocations } from './api';
import ApiError from './ApiError';
import { queryKey } from './queryKeyFactory';
import { KitchenInfos } from './types';

interface HookProps<TData> {
  options?: Omit<
    UseQueryOptions<KitchenInfos[], ApiError, TData, typeof queryKey.internUserLocations>,
    'queryFn' | 'queryKey'
  >;
}

export const useInternUserLocationsQuery = <TData = KitchenInfos[]>({ options }: HookProps<TData>) => {
  return useAuthenticatedQuery(queryKey.internUserLocations, () => (token) => fetchInternUserLocations(token), options);
};
