import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';

import { getKitchenInfos } from './api';
import { queryKey } from './queryKeyFactory';
import { KitchenInfos } from './types';

interface HookProps<TData> {
  locationUuid: string;
  options?: Omit<
    UseQueryOptions<KitchenInfos, ApiError, TData, ReturnType<(typeof queryKey)['getKitchenInfos']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useGetKitchenInfosQuery = <TData = KitchenInfos>({ locationUuid, options }: HookProps<TData>) =>
  useQuery(queryKey.getKitchenInfos(locationUuid), () => getKitchenInfos(locationUuid), options);
