import { CartModifierGroupRequest, CartRequest } from '@/api/types';
import type { Cart, ItemCart, ModifierGroupCart } from '@/types';

export const formatCartModifierGroup = (modifierGroup: ModifierGroupCart, item: ItemCart): CartModifierGroupRequest => {
  const modifiers = modifierGroup?.selectedModifiers?.map((modifier) => {
    return {
      menu_item_uuid: modifier.menuItemUuid,
      quantity: modifier.quantity,
      modifier_uuid: modifier.modifierUuid,
      modifier_groups: modifier.selectedModifierGroups
        ? modifier.selectedModifierGroups.map((mg) => formatCartModifierGroup(mg, item))
        : [],
    };
  });

  return {
    modifier_group_uuid: modifierGroup.modifierGroupUuid,
    menu_item_uuid: item.menuItemUuid,
    modifiers: modifiers ?? [],
  };
};

export const formatCart = (cart: Cart): CartRequest => {
  const items = cart.items.map((item) => {
    return {
      menu_item_uuid: item.menuItemUuid,
      menu_element_uuid: item.menuElementUuid,
      quantity: item.quantity,
      customer_instructions: '',
      modifier_groups: item?.selectedModifierGroups?.map((mg) => formatCartModifierGroup(mg, item)) ?? [],
    };
  });
  return {
    restaurant_platform_id: cart.restaurantPlatformId,
    items,
    cutlery: true,
    customer_instructions: '',
    pickup_time: cart.pickupTime ?? undefined,
  };
};
