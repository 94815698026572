import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';

import type { DeliveryPlatform, MenuInfo } from '@/api/types';
import { RestaurantCard } from '@/components/RestaurantCard/RestaurantCard';
import { sortConceptsByOpeningStatusAndIsInternal } from '@/helpers/tech4partners.helpers';

interface RestaurantsListProps {
  menuInfos: MenuInfo[];
  isDelivery?: boolean;
  locationUuid: string;
  deliveryPlatform?: DeliveryPlatform;
}

export const RestaurantsList = ({ menuInfos, deliveryPlatform, isDelivery }: RestaurantsListProps) => {
  const conceptListStyleProperties = {
    flexDirection: useBreakpointValue<'column' | 'row'>({ base: 'column', lg: 'row' }),
    cardWidth: useBreakpointValue<'100%' | '47%'>({ base: '100%', lg: '47%' }),
    flexWrap: useBreakpointValue<'wrap' | 'nowrap'>({ base: 'nowrap', lg: 'wrap' }),
  };

  return (
    <Flex
      w="100%"
      gap={6}
      pb={20}
      direction={conceptListStyleProperties.flexDirection}
      flexWrap={conceptListStyleProperties.flexWrap}
      justifyContent="center"
      alignItems="center"
    >
      {sortConceptsByOpeningStatusAndIsInternal(menuInfos).map((menuInfo) => (
        <Box width={conceptListStyleProperties.cardWidth} key={menuInfo.restaurantPlatformId} alignItems="stretch">
          <RestaurantCard
            isDelivery={isDelivery}
            menuInfo={menuInfo}
            restaurantPlatforms={deliveryPlatform?.[menuInfo.conceptUuid]}
          />
        </Box>
      ))}
    </Flex>
  );
};
