import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack } from '@chakra-ui/react';
import { sortBy } from 'lodash-es';

import { RestaurantPlatform } from '@/api/types';
import { DeliveryPlatformInfo } from '@/components/DeliveryPlatformInfo';

type PlatformsDeliveryModalProps = {
  isOpen?: boolean;
  kitchenLabel: string;
  onClose?: () => void;
  restaurantPlatforms?: RestaurantPlatform[];
};

export const PlatformsDeliveryModal = ({
  isOpen = false,
  kitchenLabel,
  onClose = () => {},
  restaurantPlatforms,
}: PlatformsDeliveryModalProps) => {
  const sortedPlatforms = sortBy(restaurantPlatforms, 'platformLabel');

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={{ width: '100%', maxWidth: '22rem' }}>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody as={VStack} gap={6} paddingX={4} paddingBlock={6} align="stretch">
          {sortedPlatforms.map(({ platformLabel, platformLogo, platformUuid, platformUrl }) => {
            return (
              <DeliveryPlatformInfo
                key={platformUuid}
                platformLabel={platformLabel}
                platformLogo={platformLogo}
                platformUrl={platformUrl}
                kitchenLabel={kitchenLabel}
              />
            );
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
