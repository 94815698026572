import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Stack,
  Text,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';

import { Item } from '@/api/gateway-click-collect/restaurants';
import type { MenuElement, ModifierGroup } from '@/api/types';
import ItemDrawerHeader from '@/components/ItemDrawer/ItemDrawerHeader';
import ModifierGroupInputs from '@/components/ModifierGroupInputs';
import NumberInput from '@/components/NumberInput';
import useFormatPrice from '@/hooks/useFormatPrice';
import useWindowSize from '@/hooks/useWindowSize';
import type { ItemCart, ModifierCart, ModifierGroupCart } from '@/types';

interface Props {
  currencyCode: string;
  isSubmitDisabled: boolean;
  disabledReason: string;
  isOpen: boolean;
  menuElement: MenuElement | Item | null;
  onClose: () => void;
  onAddToCart: (item: ItemCart) => void;
}

const ItemDrawer = ({
  currencyCode,
  menuElement,
  onClose,
  isOpen,
  isSubmitDisabled,
  disabledReason,
  onAddToCart,
}: Props) => {
  const { formatPrice } = useFormatPrice();

  const drawerPlacement = useBreakpointValue<'bottom' | 'right'>({ base: 'bottom', md: 'right' });
  const drawerSize = useBreakpointValue<'full' | 'md'>({ base: 'full', lg: 'md' });
  const { height } = useWindowSize();

  const [isDisabled, setIsDisabled] = useBoolean(true);
  const [itemCart, setItemCart] = useImmer<ItemCart | null>(null);

  useEffect(() => {
    if (menuElement && isOpen) {
      setItemCart({ ...menuElement, quantity: 1, price: menuElement.sellingPrice } as ItemCart);
    }
  }, [menuElement, isOpen, setItemCart]);

  const checkModifierGroup = useCallback((modifierGroup: ModifierGroupCart): boolean => {
    const { max, included } = modifierGroup;

    let modifierQuantity = 0;

    for (const modifier of modifierGroup.selectedModifiers || []) {
      if (modifier.available) {
        modifierQuantity += modifier.quantity || 1;

        if (modifier.selectedModifierGroups) {
          for (const modifierGroup of modifier.selectedModifierGroups) {
            if (modifierGroup.available) {
              if (!checkModifierGroup(modifierGroup)) {
                return false;
              }
            }
          }
        }
      }
    }
    return modifierQuantity >= included && modifierQuantity <= max;
  }, []);

  const checkItemCart = useCallback(
    (itemCart: ItemCart) => {
      const requiredModifierGroups = itemCart.modifierGroups.filter((mg) => mg.included > 0).length;
      const requiredSelectedModifierGroups =
        itemCart.selectedModifierGroups?.filter((mg) => mg.included > 0).length ?? 0;

      if (requiredModifierGroups !== requiredSelectedModifierGroups) return setIsDisabled.on();

      for (const modifierGroup of itemCart.selectedModifierGroups || []) {
        if (modifierGroup.available) {
          const isValidModifierGroup = checkModifierGroup(modifierGroup);

          if (!isValidModifierGroup) {
            setIsDisabled.on();
            return;
          }
        }
      }
      setIsDisabled.off();
    },
    [checkModifierGroup, setIsDisabled]
  );

  useEffect(() => {
    if (itemCart) {
      checkItemCart(itemCart);
    }
  }, [checkItemCart, itemCart]);

  if (!menuElement) {
    return null;
  }

  const updateQuantity = (quantity: number) => {
    setItemCart((draft) => {
      if (!draft) {
        return;
      }
      draft.quantity = quantity;
    });
  };

  const getPrice = (modifierGroups: ModifierGroupCart[]): number => {
    return modifierGroups.reduce((price, modifierGroup): number => {
      if (!modifierGroup.selectedModifiers) return price;

      const modifiersPrice = modifierGroup.selectedModifiers.reduce((previous, modifier): number => {
        const modifierQuantity = modifier.quantity || 1;
        let nestedModifierGroupsPrice = 0;

        if (modifier.selectedModifierGroups) {
          nestedModifierGroupsPrice = modifier.selectedModifierGroups.reduce(
            (nestedModifierGroupsPrice, nestedModifierGroup): number => {
              let nestedModifiersPrice = nestedModifierGroupsPrice;

              if (nestedModifierGroup.selectedModifiers) {
                nestedModifiersPrice += nestedModifierGroup.selectedModifiers.reduce(
                  (tmpNestedModifierPrice, nestedModifier): number => {
                    if (nestedModifier.sellingPrice && nestedModifier.quantity) {
                      return (
                        tmpNestedModifierPrice +
                        nestedModifier.sellingPrice * nestedModifier.quantity * modifierQuantity
                      );
                    }

                    return tmpNestedModifierPrice;
                  },
                  0
                );
              }
              return nestedModifiersPrice;
            },
            0
          );
        }
        if (modifier.sellingPrice && modifier.quantity) {
          return previous + modifier.sellingPrice * modifier.quantity + nestedModifierGroupsPrice;
        }
        return previous + nestedModifierGroupsPrice;
      }, 0);
      return price + modifiersPrice;
    }, 0);
  };

  const updatePrice = () => {
    setItemCart((draft) => {
      if (!draft) {
        return;
      }
      if (draft.selectedModifierGroups && Array.isArray(draft.selectedModifierGroups)) {
        const selectedModifierPrice = getPrice(draft.selectedModifierGroups);
        draft.price = draft.sellingPrice * draft.quantity + selectedModifierPrice * draft.quantity;
      } else {
        draft.price = draft.sellingPrice * draft.quantity;
      }
    });
  };

  const onUpdateQuantity = (quantity: number) => {
    if (itemCart) {
      updateQuantity(quantity);
      updatePrice();
    }
  };

  const onUpdateSelectedModifierGroup = (modifierGroup: ModifierGroup, selectedModifiers: ModifierCart[]) => {
    if (itemCart?.selectedModifierGroups) {
      setItemCart((draft) => {
        if (draft?.selectedModifierGroups) {
          const foundModifierGroup = draft.selectedModifierGroups.find(
            ({ modifierGroupUuid }) => modifierGroupUuid === modifierGroup.modifierGroupUuid
          );
          if (foundModifierGroup) {
            foundModifierGroup.selectedModifiers = selectedModifiers;
          } else {
            draft.selectedModifierGroups = [
              ...draft.selectedModifierGroups,
              { ...modifierGroup, selectedModifiers: selectedModifiers },
            ];
          }
        }
      });
    } else {
      setItemCart((cart) => {
        if (cart) {
          cart.selectedModifierGroups = [{ ...modifierGroup, selectedModifiers: selectedModifiers }];
        }
      });
    }
    updatePrice();
  };

  return (
    <Drawer placement={drawerPlacement} size={drawerSize} isFullHeight onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent overflow="auto" maxH={height}>
        <DrawerCloseButton backgroundColor="white" borderRadius="50%" />
        <ItemDrawerHeader menuElement={menuElement as MenuElement} />
        <DrawerBody p={0} overflow="none">
          <Stack gap={4}>
            {itemCart &&
              menuElement.modifierGroups?.map((modifierGroup) => {
                return (
                  <ModifierGroupInputs
                    onUpdateSelectedModifierGroup={onUpdateSelectedModifierGroup}
                    key={modifierGroup.modifierGroupUuid}
                    currencyCode={currencyCode}
                    modifierGroup={modifierGroup as ModifierGroup}
                  />
                );
              })}
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Box w="100%" display="flex" flexDir="column" gap={4}>
            <NumberInput
              label={t`Quantity`}
              size="md"
              fontSize="xl"
              min={1}
              defaultValue={1}
              onDecrement={onUpdateQuantity}
              onIncrement={onUpdateQuantity}
              isDisabled={false}
            />
            <Button
              isDisabled={isDisabled || isSubmitDisabled}
              onClick={() => itemCart && onAddToCart(itemCart)}
              type="submit"
              size="lg"
              w="100%"
            >
              {itemCart && itemCart.price && (
                <Text>
                  <Trans>Add to cart</Trans> &bull; {formatPrice(itemCart.price, currencyCode)}
                </Text>
              )}
            </Button>
            {isSubmitDisabled && <Text textAlign="center">{disabledReason}</Text>}
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ItemDrawer;
