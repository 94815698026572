import { Box, Flex, Stack, Text, useBoolean, useRadioGroup } from '@chakra-ui/react';
import { defineMessage, Trans } from '@lingui/macro';
import { Dayjs } from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { useEffect, useState } from 'react';

import { Shift } from '@/api/types';
import PickupTimeSelector from '@/components/PickupForm/PickupTimeSelector';
import PickupTypeCard from '@/components/PickupForm/PickupTypeCard';
import usePreOrder from '@/hooks/usePreOrder';
import { PickupTypesEnum } from '@/types';

interface Props {
  isOpen: boolean;
  shifts: Shift[];
  onTypeChange: (value: PickupTypesEnum) => void;
  onTimeChange: (value: Dayjs | null) => void;
}

const PICKUP_TYPE_OPTIONS = [
  { label: defineMessage({ message: 'ASAP (10min)' }), value: PickupTypesEnum.ASAP },
  { label: defineMessage({ message: 'Later' }), value: PickupTypesEnum.LATER },
];

const getPickupTypeOptions = (isRestaurantClosed: boolean, preOrderTimes: Dayjs[]) => {
  if (isRestaurantClosed) return [PICKUP_TYPE_OPTIONS[1]];
  else if (preOrderTimes.length === 0) return [PICKUP_TYPE_OPTIONS[0]];
  return PICKUP_TYPE_OPTIONS;
};

function PickupForm({ isOpen, shifts, onTypeChange, onTimeChange }: Props) {
  const { preOrderTimes } = usePreOrder({ shifts, refreshInterval: 2000 });
  const isRestaurantClosed = !isOpen;

  const [pickupTypeOptions, setPickupTypeOptions] = useState(getPickupTypeOptions(isRestaurantClosed, preOrderTimes));

  const [pickupType, setPickupType] = useState<PickupTypesEnum>(pickupTypeOptions[0].value);

  const [pickupTime, setPickupTime] = useState<Dayjs | null>(null);
  const [isTimeSelectorVisible, setIsTimeSelectorVisible] = useBoolean(false);

  const { getRadioProps, getRootProps } = useRadioGroup({
    onChange: (value) => setPickupType(value as PickupTypesEnum),
    value: pickupType,
  });

  useEffect(() => {
    if (pickupType === PickupTypesEnum.LATER) {
      setIsTimeSelectorVisible.on();
    } else {
      setIsTimeSelectorVisible.off();
      setPickupTime(null);
    }

    onTypeChange(pickupType);
  }, [pickupType]);

  useEffect(() => {
    const _pickupTimeOptions = getPickupTypeOptions(isRestaurantClosed, preOrderTimes);

    if (_pickupTimeOptions.length !== pickupTypeOptions.length) {
      setPickupTypeOptions(_pickupTimeOptions);
      setPickupType(_pickupTimeOptions[0].value);

      if (_pickupTimeOptions[0].value === PickupTypesEnum.LATER) {
        setIsTimeSelectorVisible.on();
      }
    }
  }, [isRestaurantClosed, preOrderTimes]);

  useEffect(() => {
    const pickupTypeOptionsCopy = cloneDeep(getPickupTypeOptions(isRestaurantClosed, preOrderTimes));

    if (pickupTime !== null) {
      const laterOption = pickupTypeOptionsCopy.find((o) => o.value === PickupTypesEnum.LATER);

      if (laterOption) {
        laterOption.label.id = pickupTime.calendar();
      }
    }

    setPickupTypeOptions(pickupTypeOptionsCopy);

    onTimeChange(pickupTime);
  }, [pickupTime]);

  const handleTimeSelectorConfirm = (pickupTime: Dayjs) => {
    setPickupTime(pickupTime);
    setIsTimeSelectorVisible.off();
  };

  const showTimeSelector = () => {
    setIsTimeSelectorVisible.on();
  };

  const radioGroupProps = getRootProps();

  const shouldDisplayPickupOption = (pickupOption: PickupTypesEnum): boolean | undefined => {
    if (!isRestaurantClosed) {
      return pickupOption === PickupTypesEnum.ASAP ? true : preOrderTimes.length > 0;
    } else {
      return pickupOption === PickupTypesEnum.ASAP ? false : !!pickupTime;
    }
  };

  return (
    <Flex flexDirection="column">
      <Text marginBottom="6px" fontWeight="500" fontSize="md">
        <Trans>Pickup time</Trans>
      </Text>
      <Stack gap={2} {...radioGroupProps} mb={4}>
        {pickupTypeOptions.map(({ value, label }) => {
          const radioProps = getRadioProps({ value });

          return (
            shouldDisplayPickupOption(value) && (
              <Box {...(pickupTime !== null ? { onClick: showTimeSelector } : {})} key={value}>
                <PickupTypeCard {...radioProps}>
                  <Trans id={label.id} />
                </PickupTypeCard>
              </Box>
            )
          );
        })}
      </Stack>
      {pickupType === PickupTypesEnum.LATER && (
        <PickupTimeSelector
          preOrderTimes={preOrderTimes}
          isVisible={isTimeSelectorVisible}
          onConfirm={handleTimeSelectorConfirm}
        />
      )}
    </Flex>
  );
}

export default PickupForm;
