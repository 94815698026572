import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { restaurantsKeys } from './restaurants.helpers';
import { ListRestaurantsRequest, Restaurant } from './types';

interface HookProps<TData> {
  requestParams: ListRestaurantsRequest;
  options?: Omit<
    UseQueryOptions<Restaurant[], ApiError, TData, ReturnType<(typeof restaurantsKeys)['list']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useLocationRestaurantsQuery = <TData = Restaurant[]>({ requestParams, options }: HookProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery(restaurantsKeys.list(requestParams), () => restaurantsApi.listRestaurants(requestParams), options);
};
