import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';

import { conceptKeys } from './concepts.helpers';
import { GetConfigurationRequest, ModelConfiguration } from './types';

interface HookProps<TData> {
  requestParams?: GetConfigurationRequest;
  options?: Omit<
    UseQueryOptions<ModelConfiguration, ApiError, TData, ReturnType<(typeof conceptKeys)['getConfiguration']>>,
    'queryFn' | 'queryKey'
  >;
}

export const useConceptsConfigurationQuery = <TData = ModelConfiguration>({
  requestParams,
  options = {},
}: HookProps<TData>) => {
  const {
    gatewayClickCollect: { conceptsApi },
  } = useTasterApis();

  return useQuery(
    conceptKeys.getConfiguration(requestParams),
    () => conceptsApi.getConfiguration(requestParams),
    options
  );
};
